import React from "react"
import Header from "../../../components/header/header.component"
import Cart from '../cart.service'
import Item from "./item/item.component"
import './cart.styles.scss'
import NumberFormat from "react-number-format"
import EmptyList from '../../../components/empty-list/empty-list.component'
import Toast from "../../../components/ui/toast/toast.component"

class CartPage extends React.Component {
    constructor(props) {
        super(props)
        this.cart = Cart()
        this.state = {
            items: this.cart.items()
        }
    }

    componentDidMount(){
        document.title = 'Marketplace | Cart'
    }

    onDecrease = index => {
        let quantity = this.state.items[index].quantity
        if (quantity > 1) {
            quantity--
        }
        this.updateQuantity(index, quantity)
    }

    onIncrease = index => {
        let quantity = this.state.items[index].quantity

        quantity++

        if (this.state.items[index].deliverable.type === 2 && quantity > this.state.items[index].quantityAvailable) {
            Toast().show("max available quantity reached")
        }
        else if (quantity > this.state.items[index].maxOrderQuantity) {
            Toast().show("max quantity per order reached")
        }
        else {
            this.updateQuantity(index, quantity)
        }        
    }

    updateQuantity = (index, quantity) => {
        let items = this.state.items
        items[index].quantity = quantity
        this.setState({
            ...this.state,
            items: items
        })
        this.cart.save(items)
    }

    onDeleteClicked = index => {
        let items = this.state.items.filter((item, _index) => _index !== index)
        this.setState({
            ...this.state,
            items: items
        })
        this.cart.save(items)
    }

    onContinueshoppingClicked = () => this.props.history.push('/marketplace')

    onCheckoutClicked = () => this.props.history.push('/marketplace/addresses')

    getTotal = () => {
        let total = 0
        for (let i = 0; i < this.state.items.length; i++) {
            total += (this.state.items[i].amount * this.state.items[i].quantity)
        }
        return total
    }

    render() {
        return (
            <>
                <Header title="Cart" backUrl="/marketplace" />
                <div className="cart">
                    <div className="items">
                        {
                            this.state.items.map((item, key) => <Item key={key} {...item} onIncrease={() => this.onIncrease(key)} onDecrease={() => this.onDecrease(key)} onDelete={() => this.onDeleteClicked(key)} />)
                        }
                    </div>
                    {
                        this.state.items.length > 0 &&
                        <>
                            <div className="subtotal">
                                <p>Subtotal ({this.state.items.length} {this.state.items.length > 1 ? 'Items' : 'Item'})</p>
                                <NumberFormat value={this.getTotal() / 100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>N{value}</strong>} />
                            </div>
                            <button className="btn action" onClick={this.onCheckoutClicked}>Checkout</button>
                        </>
                    }
                    {
                        this.state.items.length === 0 && <EmptyList title="oops! no items in cart" />
                    }
                    <button className="btn action continue" onClick={this.onContinueshoppingClicked}>Continue Shopping</button>
                </div>
            </>
        )
    }
}

export default CartPage
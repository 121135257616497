import React from 'react'
import './event.styles.scss'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'

const Event = props => {
    return (
        <div className="event" onClick={() => props.history.push("/ticketing/" + props.code.toUpperCase())}>
            <div>
                <h4>{props.name}</h4>
                <h6>{props.description}</h6>
                <p>
                    {
                        props.type === 0 ? <span>Free Ticket</span> : <><i>Ticket from</i> <NumberFormat value={props.lowestAmount / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>N{value}</span>} /></>
                    }
                </p>
            </div>
            <img alt="" src={props.imageUrl} />
        </div>
    )
}

export default withRouter(Event)
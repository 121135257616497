import React from "react"
import './event-info.styles.scss'

const EventInfo = props => {
    return (
        <div className="event-info">
            <div>
                <h4>{props.name}</h4>
                <p>
                    {props.description}
                </p>
            </div>
            <img alt="icon" src={props.imageUrl} />
        </div>
    )
}

export default EventInfo
import React from 'react'
import Dialog from '../dialog/dialog.component'
import iconPaymentSuccess from '../../assets/icons/payment-success.svg'
import iconClose from '../../assets/icons/close.svg'
import { Link, withRouter } from 'react-router-dom'
import './payment-success.styles.scss'

const PaymentSuccess = props => {
    let interval = window.setInterval(() => {
        window.clearInterval(interval)
        props.history.push(props.redirectUrl)
    }, 2000)
    return (
        <Dialog>
            <div className="payment-success">
                <Link to={props.redirectUrl}>
                    <img alt="close" src={iconClose} />
                </Link>
                <div>
                    <h4>Payment Successful</h4>
                    <img alt="success" src={iconPaymentSuccess} />
                </div>
            </div>
        </Dialog>
    )
}

export default withRouter(PaymentSuccess)
import Requests from "../../../../../utils/Requests"

const getDefaultState = instance => {
    return {
        deleting: false,
        showError: false,
        alertError: {}
    }
}

const deleteAddress = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/delivery-address/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData({ deliveryAddressId: instance.props.address.id })
        .then(response => {
            handleDeleteAddressResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeleteAddressResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.onDeleted()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDeleteAddress,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deleteAddress: () => deleteAddress(instance)
    }
}

export default Service
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import store from '../../redux/stores/store'
import { AUTH_SAVE } from '../../redux/reducers/auth/action-types'
import Device from '../../utils/Device'

class RequireLogin extends React.Component {

    componentDidMount() {
        !Device.getAuth() && this.redirectToLogin()
    }

    redirectToLogin = () => {
        if (Device.getInfo().type !== Device.types.ios) {
            this.props.history.push('/auth/login?return=' + window.location.pathname)
        }
        else {
            this.props.history.push('/auth/login')
        }
    }

    render() {
        if (!Device.getAuth()) {
            return (
                <React.Fragment></React.Fragment>
            )
        }
        if (!store.getState().auth || !store.getState().auth.authorization) {
            this.props.dispatch({
                type: AUTH_SAVE,
                payload: JSON.parse(Device.getAuth())
            })
            return (
                <React.Fragment></React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(RequireLogin))
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import GetPassword from './components/get-password/get-password.component'
import Service from './delete-account.service'
import './delete-account.styles.scss'
import Dialog from '../../../components/dialog/dialog.component'
import Header from '../../../components/header/header.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class DeleteAccount extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Settings | Delete Account'
    }

    onPasswordReceived = password => {
        this.setState({
            ...this.state,
            password: password,
            deleting: true
        }, this.service.deleteAccount)
    }

    onDeleteAccount = () => {
        this.setState({
            ...this.state,
            deleting: true
        }, this.service.deleteAccount)
    }

    toggleShowPasswordDialog = () => {
        this.setState({
            ...this.state,
            showPasswordDialog: !this.state.showPasswordDialog
        })
    }

    render() {
        return (
            <>
                {this.state.deleting && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                {this.state.showPasswordDialog && <Dialog><GetPassword onPasswordReceived={this.onPasswordReceived} onCancel={this.toggleShowPasswordDialog} /></Dialog>}
                <Header title="Delete Account" backUrl="/settings"/>
                <div className="delete-account">
                    <h4>We are unhappy to see you leave</h4>
                    <p>
                        You can always create a new account and start afresh.
                    </p>
                    <button className="btn action" onClick={this.toggleShowPasswordDialog}>Delete Account</button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DeleteAccount))
import React from 'react'
import Tag from './tag/tag.component'
import './tags.styles.scss'

const Tags = props => {
    return (
        <div className='tags'>
            {
                props.tags.map((tag, key) => <Tag key={key} {...tag} onChange={e => props.onChange(e)} />)
            }
        </div>
    )
}

export default Tags
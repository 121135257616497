import React from "react"
import iconDstv from '../../../../assets/icons/dstv-white.svg'
import iconGotv from '../../../../assets/icons/gotv.svg'
import iconStartimes from '../../../../assets/icons/startimes.svg'
import './decoder-info.styles.scss'

const DecoderInfo = props => {
    let iconInfo = getIcon(props.type)
    return (
        <div className="decoder-info">
            <div className={iconInfo.class}>
                <img alt="icon" src={iconInfo.src} />
            </div>
            <div>
                <h4>{props.alias}</h4>
                <p>
                    {props.number}
                </p>
            </div>
        </div>
    )
}

const getIcon = type => {
    switch (type) {
        case 0:
            return {
                class: "dstv",
                src: iconDstv
            }
        case 1:
            return {
                class: "gotv",
                src: iconGotv
            }
        default:
            return {
                class: "startimes",
                src: iconStartimes
            }
    }
}

export default DecoderInfo
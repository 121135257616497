import React from 'react'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Email</label>
                <input type="email" name="email" value={props.email} placeholder="Enter your registered email" onChange={props.onChange} />
                <span className="error">{props.errors.email}</span>
            </div>
            <button className="btn action">Continue</button>
        </form>
    )
}

export default Form
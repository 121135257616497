import React from 'react'
import './slide.styles.scss'

const Slide = props => {
    return (
        <div className={props.active ? "slide active" : "slide"}>
            <img src={props.image} alt="" />
            <div className="info">
                <h3>{props.title}</h3>
                <p>
                    {props.description}
                </p>
            </div>
        </div>
    )
}

export default Slide;
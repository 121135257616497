import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './create-dedicated-nuban.styles.scss'
import Service from './create-dedicated-nuban.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../dialog/alert/alert.component'

class CreateDedicatedNuban extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onCreateClick = () => {
        this.setState({
            ...this.state,
            creating: true,
            showError: false,
        }, () => this.service.createDedicatedNuban())
    }

    render() {
        return (
            <div className="app-create-dedicated-nuban">
                {this.state.creating && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                <h5 className="title">Wallet Not Linked to an Account</h5>
                <button className="btn action" onClick={this.onCreateClick}>Create Wallet Account</button>
                <p>
                A Dedicated Account Number will be created and assigned to your Wallet
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(CreateDedicatedNuban))
import React from 'react'
import './header.styles.scss'
import iconBack from '../../assets/icons/arrow-back.svg'
import { Link } from 'react-router-dom'
import CartBox from './cart-box/cart-box.component'

const Header = props => {
    return (
        <header className="app">
            <Link className="back" to={props.backUrl}><img alt="back" src={iconBack}/></Link>
            <p>{props.title}</p>
            {props.cartBox && <CartBox/>}
        </header>
    )
}

export default Header
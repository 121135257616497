import React from 'react'
import './menu.styles.scss'
import imageArrow from '../../../assets/icons/arrow-forward.svg'
import imageAvatar from '../../../assets/icons/avatar.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Device from '../../../utils/Device'
import { Link } from 'react-router-dom'

const Menu = props => {
    return (

        <div className="menu">
            <div className="info">
                <img src={imageAvatar} alt="avatar" />
                <h4>{getName(props)}</h4>
                <p>{getEmail(props)}</p>
            </div>
            <div className="list">
                <div className="item" onClick={() => props.history.push('/settings/profile')}>
                    <p>Edit Account</p>
                    <img src={imageArrow} alt="arrow" />
                </div>
                <div className="item" onClick={() => props.history.push('/settings/change-password')}>
                    <p>Change Password</p>
                    <img src={imageArrow} alt="arrow" />
                </div>
                <div className="item" onClick={() => props.history.push('/settings/help')}>
                    <p>Get Help</p>
                    <img src={imageArrow} alt="arrow" />
                </div>
                <Link className="item" to="/pages/privacy">
                    <p>Privacy Policy</p>
                    <img src={imageArrow} alt="arrow" />
                </Link>
                <Link className="item" to="/pages/terms">
                    <p>Terms & Conditions</p>
                    <img src={imageArrow} alt="arrow" />
                </Link>
                {
                    Device.getInfo().type === Device.types.web &&
                    <div className="item" onClick={() => logout(props)}>
                        <p>Log out</p>
                        <img src={imageArrow} alt="arrow" />
                    </div>
                }
                <div className="item" onClick={() => props.history.push('/settings/delete-account')}>
                    <p>Delete Account</p>
                    <img src={imageArrow} alt="arrow" />
                </div>
            </div>
        </div>
    )
}

const getName = props => {
    if (typeof props.auth.user !== 'undefined') {
        return props.auth.user.firstname + " " + props.auth.user.lastname
    }
    return props.auth.customer.firstname + " " + props.auth.customer.lastname
}

const getEmail = props => {
    if (typeof props.auth.user !== 'undefined') {
        return props.auth.user.email
    }
    return props.auth.customer.email
}

const logout = props => {
    sessionStorage.removeItem("auth")
    window.location.reload();
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Menu))
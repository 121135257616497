import React from 'react'
import { Switch, Route } from "react-router-dom"
import Decoders from './decoders/decoder.controller'
import Order from './order/order.page'
import CreateOrder from './order/create/create.page'

const CabletvController = () => {
    return (
        <div className="cabletv">
            <Switch>
                <Route path="/cabletv/order/create" component={CreateOrder} />
                <Route path="/cabletv/order" component={Order} />
                <Route path="/cabletv" component={Decoders} />
            </Switch>
        </div>
    )
}

export default CabletvController
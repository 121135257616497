import { SET_DECODERS, SET_PACKAGES, ADD_DECODER } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case ADD_DECODER:
            return addDecoder(state, action.payload)
        case SET_DECODERS:
            return setDecoders(state, action.payload)
        case SET_PACKAGES:
            return setPackages(state, action.payload)
        default:
            return state
    }
}

const addDecoder = (state, payload) => {
    for(let i = 0; i < state.decoders.length; i++){
        if(state.decoders[i].number === payload.number){
            return state
        }
    }
    state.decoders.push(payload)
    state.cache.decoders = new Date().getTime()
    return state
}

const setDecoders = (state, payload) => {
    state.decoders = payload
    state.cache.decoders = new Date().getTime()
    return state
}

const setPackages = (state, payload) => {
    state.packages = payload
    state.cache.packages = new Date().getTime()
    return state
}

export default Reducer
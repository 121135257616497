import Requests from "../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingSlides: true,
        slides: [],
        showError: false,
        alertError: {}
    }
}

const loadSlides = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/services/slides/featured")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleloadSlidesResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleloadSlidesResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingSlides: false,
                slides: response.data
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingSlides: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadSlides: () => loadSlides(instance)
    }
}

export default Service
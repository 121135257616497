import React from 'react'
import './beneficiary.scss'
import iconMtn from '../../../../../assets/icons/mtn-white.svg'
import iconAirtel from '../../../../../assets/icons/airtel-white.svg'
import iconGlo from '../../../../../assets/icons/glo-white.svg'
import icon9Mobile from '../../../../../assets/icons/9mobile-white.svg'
import iconDelete from '../../../../../assets/icons/delete.svg'
import iconArrowForward from '../../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'

const Beneficiary = props => {
    let iconInfo = getIcon(props.network)
    return (
        <div className="beneficiary">
            <div className="content" onClick={props.onClick}>
                <div className={iconInfo.class}>
                    <img alt="icon" src={iconInfo.src} />
                </div>
                <div>
                    <h4>{props.name}</h4>
                    <p>
                        {props.number}
                    </p>
                </div>
                <img src={iconArrowForward} alt="icon" />
            </div>
            <img src={iconDelete} alt="delete" onClick={props.onDeleteClick} />
        </div>
    )
}

const getIcon = network => {
    switch (network) {
        case 0:
            return {
                class: "mtn",
                src: iconMtn
            }
        case 1:
            return {
                class: "airtel",
                src: iconAirtel
            }
        case 2:
            return {
                class: "glo",
                src: iconGlo
            }
        default:
            return {
                class: "_9mobile",
                src: icon9Mobile
            }
    }
}

export default withRouter(Beneficiary)
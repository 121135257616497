import React from 'react'
import './address.styles.scss'
import iconArrowForward from '../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'
import iconDelete from '../../../../assets/icons/delete.svg'

const Address = props => {
    return (
        <div className="address">
            <div className="content" onClick={props.onClick}>
                <div>
                    <h4>{props.contactName}</h4>
                    <p>{props.contactPhone}</p>
                    <p>{props.street}</p>
                    <p>{props.landmark}</p>
                    <p>{props.area.name}, {props.area.route.name}</p>
                </div>
                <img src={iconArrowForward} alt="icon" />
            </div>
            <img src={iconDelete} alt="delete" onClick={props.onDeleteClick}/>
        </div>
    )
}

export default withRouter(Address)
import Requests from "../../../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingDataBundles: true,
        dataBundles: [],
        showError: false,
        alertError: {}
    }
}

const loadDataBundles = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/airtime/data-bundles")
        .withAuthorization(instance.props.auth.authorization)
        .withData({network: instance.props.network})
        .then(response => {
            handleLoadDataBundlesResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDataBundlesResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDataBundles: false,
                dataBundles: response.data.dataBundles
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}
const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDataBundles,
            onClose: () => instance.props.history.push('/cabletv')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDataBundles: () => loadDataBundles(instance)
    }
}

export default Service
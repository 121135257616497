import React from 'react'
import imgHide from './assets/images/hide.png'
import imgShow from './assets/images/show.png'
import './password.styles.scss'

class PasswordInput extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            show: false
        }
    }

    onImageClick = () => {
        this.setState({
            ...this.state,
            show: !this.state.show
        })
    }

    render() {
        return (
            <div className='password-input'>
                <input type={this.state.show ? 'text' : 'password'} name="password" value={this.props.password} placeholder={this.props.placeholder} onChange={this.props.onChange} />
                <img alt="" src={this.state.show? imgHide: imgShow} onClick={this.onImageClick}/>
            </div>
        )
    }
}

export default PasswordInput
import Validation from '../../../data/validation/validation'
import { EmailValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        processing: false,
        tab: 0,
        email: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => instance.state.errors.email = error, { allowNull: false, error: "Invalid" })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const reset = (instance) => {

    Requests(instance.props)
        .post()
        .withUrl("/auth/customer/forget-password")
        .withData({ email: instance.state.email.trim().toLowerCase() })
        .then(response => {
            handleResetResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleResetResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                tab: 1,
                processing: false,
                emailVerificationId: response.data.id
            })
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.status.message
                }
            })
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        processing: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onReset,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        reset: () => reset(instance)
    }
}

export default Worker
import React from 'react'
import './cart-box.styles.scss'
import iconCart from '../../../assets/icons/cart.svg'
import CartService from '../../../pages/marketplace/cart.service'
import { Link } from 'react-router-dom'

const CartBox = () => {
    let items = CartService().items()
    return (
        <Link className="cart-box" to="/marketplace/cart">
            <label>{items.length}</label>
            <img alt="cart" src={iconCart} />
        </Link>
    )
}

export default CartBox
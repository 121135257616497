import Validation from '../../../../data/validation/validation'
import { NumberValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        meter: getMeter(),
        amount: "",
        showCreateOrder: false,
        processing: false,
        errors: {

        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: 1, error: "Invalid" }, max: { value: 9000000, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const getMeter = () => {
    let meter = sessionStorage.getItem("currentMeter")
    if(!meter){
        window.location = "/utility/electricity"
        return null
    }
    return JSON.parse(meter);
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance)
    }
}

export default Service
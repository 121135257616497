import React from 'react'
import NumberFormat from 'react-number-format'
import { withRouter } from 'react-router-dom'
import Header from '../../../../../components/header/header.component'
import Brand from '../components/brand/brand.component'
import Service from './detail.service'
import './detail.styles.scss'
import Cart from '../../../cart.service'
import QuantityModifier from '../../../components/quantity-modifier/quantity-modifier.component'
import Toast from '../../../../../components/ui/toast/toast.component'

class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.cart = Cart()
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Marketplace | Item | ' + this.state.item.name
    }

    onAddToCart = () => {
        this.cart.add(this.state.item)
        this.props.history.push('/marketplace/cart')
    }

    onDecrease = () => {
        let quantity = this.state.item.quantity
        if (quantity > 1) {
            quantity--
        }
        this.updateQuantity(quantity)
    }

    onIncrease = () => {
        let quantity = this.state.item.quantity

        quantity++

        if (this.state.item.deliverable.type === 2 && quantity > this.state.item.quantityAvailable) {
            Toast().show("max available quantity reached")
        }
        else if (quantity > this.state.item.maxOrderQuantity) {
            Toast().show("max quantity per order reached")
        }
        else {
            this.updateQuantity(quantity)
        }
    }

    updateQuantity = quantity => {
        this.setState({
            ...this.state,
            item: {
                ...this.state.item,
                quantity: quantity
            }
        })
    }

    getItemAvailabilityLabel = () => {
        if (this.state.item.quantityAvailable === 0) {
            return <p className="stats">item not available</p>
        }

        if (this.state.item.quantityAvailable < (this.state.item.maxOrderQuantity)) {
            return <p className="stats">{this.state.item.quantityAvailable} {this.state.item.quantityAvailable === 1 ? 'item' : 'items'} left</p>
        }
    
        return <></>
    }

    render() {
        if (this.state.item === null) {
            return <></>
        }
        return (
            <>
                <Header title="Marketplace" backUrl={"/marketplace/" + this.state.item.deliverable.sku.toLowerCase()} cartBox={true} />
                <div className="detail">
                    <Brand {...this.state.item.deliverable} imageUrl={this.state.item.imageUrl} />
                    <br />
                    <QuantityModifier value={this.state.item.quantity} onDecrease={this.onDecrease} onIncrease={this.onIncrease} />
                    {this.state.item.deliverable.type === 2 && this.getItemAvailabilityLabel()}
                    <br />
                    <div className='item-info'>
                        <div>
                            <h4>{this.state.item.name}</h4>
                            <NumberFormat value={this.state.item.amount / 100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>N{value}</strong>} />
                        </div>
                        <h5>Discription</h5>
                        <p>{this.state.item.description}</p>
                    </div>
                    {
                        (this.state.item.deliverable.type < 2 || this.state.item.quantityAvailable > 0) && <button className="btn action" onClick={this.onAddToCart}>Add to Cart</button>
                    }
                </div>
            </>
        )
    }
}

export default withRouter(Detail)
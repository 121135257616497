import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Menu from './menu/menu.page'
import Profile from './profile/profile.page'
import ChangePassword from './change-password/change-password.page'
import Help from './help/help.page'
import DeleteAccount from './delete-account/delete-account.page'
import './common.styles.scss'

const Controller = () => {
    return (
        <div className="settings">
            <Switch>
                <Route path="/settings/profile" component={Profile} />
                <Route path="/settings/change-password" component={ChangePassword} />
                <Route path="/settings/help" component={Help} />
                <Route path="/settings/delete-account" component={DeleteAccount} />
                <Route exact path="/settings" component={Menu} />
            </Switch>
        </div>
    )
}

export default Controller
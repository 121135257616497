import Requests from "../../../../../../utils/Requests"

const getDefaultState = instance => {
    return {
        deleting: false,
        showError: false,
        alertError: {}
    }
}

const deleteMeter = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/electricity/meter/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData({ meterId: instance.props.meter.id })
        .then(response => {
            handleDeleteMeterResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeleteMeterResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.onDeleted()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDeleteMeter,
            onClose: () => instance.props.history.push('/cabletv')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deleteMeter: () => deleteMeter(instance)
    }
}

export default Service
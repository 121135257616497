import React from 'react'
import { Switch, Route } from "react-router-dom"
import Meters from './meters.page'
import Create from './create/create.page'

const MeterController = () => {
    return (
        <div className="decoder">
            <Switch>
                <Route path="/utility/electricity/meter/create" component={Create} />
                <Route path="/utility/electricity" component={Meters} />
            </Switch>
        </div>
    )
}

export default MeterController
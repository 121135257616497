import Requests from "../../../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingAreas: true,
        areas: [],
        showError: false,
        alertError: {}
    }
}

const loadAreas = instance => {

    let data = {
        offset: 0,
        maxSize: 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/delivery/areas")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadAreasResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadAreasResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingAreas: false,
                areas: response.data.areas
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}
const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadAreas,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadAreas: () => loadAreas(instance)
    }
}

export default Service
import React from 'react'
import './varieties.styles.scss'

const Varieties = props => {
    return (
        <div className="varieties">
            <p>Choose Variety</p>
            <div className="list">
                {
                    props.varieties.map((variety, key) => <span className={props.currentVariety === variety ? "variety active" : "variety"} key={key} onClick={() => props.onChange(variety)}>{variety}</span>)
                }
            </div>
        </div>
    )
}

export default Varieties
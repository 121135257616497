import React from "react"
import iconClose from '../../../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../../../components/dialog/dialog.component"
import './distributor-chooser.styles.scss'
import iconBEDC from '../../../../../../../../assets/icons/bedc-only.svg'
import iconEKEDC from '../../../../../../../../assets/icons/ekedc.svg'
import iconIBEDC from '../../../../../../../../assets/icons/ibedc.svg'
import iconIKEDC from '../../../../../../../../assets/icons/ikedc.svg'
import iconJED from '../../../../../../../../assets/icons/jed.svg'
import iconKEDCO from '../../../../../../../../assets/icons/kedco.svg'
import iconKAEDCO from '../../../../../../../../assets/icons/kaedco.svg'
import iconPHED from '../../../../../../../../assets/icons/phed.svg'
import iconAEDC from '../../../../../../../../assets/icons/aedc.svg'
import iconEEDC from '../../../../../../../../assets/icons/eedc.svg'

const DistributorChooser = props => {
    return (
        <Dialog>
            <div className="distributor-chooser">
                <h3>
                    <span>Select Power Distributor</span>
                    <img alt="close" src={iconClose} onClick={props.onClose} />
                </h3>
                <div className="distributors">
                    <div className="disco" onClick={() => props.onSelect(0)}>
                        <img alt="icon" src={iconBEDC} />
                        <h4>Benin Electricity (BEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(1)}>
                        <img alt="icon" src={iconIKEDC} />
                        <h4>Ikeja Electricity (IKEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(2)}>
                        <img alt="icon" src={iconEKEDC} />
                        <h4>Eko Electricity (EKEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(3)}>
                        <img alt="icon" src={iconPHED} />
                        <h4>Port Harcourt Electricity (PHED)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(4)}>
                        <img alt="icon" src={iconAEDC} />
                        <h4>Abuja Electricity (AEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(9)}>
                        <img alt="icon" src={iconEEDC} />
                        <h4>Enugu Electricity (EEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(6)}>
                        <img alt="icon" src={iconJED} />
                        <h4>Jos Electricity (JED)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(7)}>
                        <img alt="icon" src={iconIBEDC} />
                        <h4>Ibadan Electricity (IBEDC)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(8)}>
                        <img alt="icon" src={iconKAEDCO} />
                        <h4>Kaduna Electricity (KAEDCO)</h4>
                    </div>
                    <div className="disco" onClick={() => props.onSelect(5)}>
                        <img alt="icon" src={iconKEDCO} />
                        <h4>Kano Electricity (KEDCO)</h4>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default DistributorChooser
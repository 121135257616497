import React from 'react'
import DecoderType from './decoder-type/decoder-type.component'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="distributor">
                    <label>Cable Provider</label>
                    <DecoderType type={props.type} onChange={props.onChange} />
                    <span className="error">{props.errors.type}</span>
                </div>
                <div className="row" id="number">
                    <label>Smartcard/IUC Number</label>
                    <div className="form-input">
                        <input type="text" name="number" placeholder="Enter Smartcard Number" value={props.number} onChange={props.onChange} />
                        <span className="error">{props.errors.number}</span>
                    </div>
                </div>
                <div className="row" id="alias">
                    <label>Nickname</label>
                    <div className="form-input">
                        <input type="text" name="alias" placeholder="Enter Nickname" value={props.alias} onChange={props.onChange} />
                        <span className="error">{props.errors.alias}</span>
                    </div>
                </div>
            </div>
            <button className="btn action" type="submit">Save & Continue</button>
        </form>
    )
}

export default Form
import React from 'react'
import Slide from './slide/slide.component'
import './welcome.styles.scss'
import imageSlide1 from '../../assets/images/slide1.png'
import imageSlide2 from '../../assets/images/slide2.png'
import imageSlide3 from '../../assets/images/slide3.png'
import { withRouter } from 'react-router'

class Welcome extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeSlide: 0,
            slides: [
                {
                    image: imageSlide1,
                    title: "Pay Bills",
                    description: "Pay DSTV, Electricity bills, Internet services, and recharge your mobile lines including that of family and friends"
                },
                {
                    image: imageSlide2,
                    title: "Fast and Secured",
                    description: "Transactions are fast and funds are secured in your wallet for your daily use"
                },
                {
                    image: imageSlide3,
                    title: "Life Made Easy",
                    description: "Enjoy convenient purchases from wherever you are"
                }
            ]
        }
    }

    componentDidMount(){
        document.title = 'Welcome'
    }

    onContinueClick = () => {
        if(this.state.activeSlide === 2){
            this.props.history.push('/dashboard')
        }
        else{
            this.setState({
                ...this.state,
                activeSlide: this.state.activeSlide + 1
            })
        }
    }

    render() {
        return (
            <div className="welcome">
                <div className="slides">
                    {
                        this.state.slides.map((slide, key) => <Slide key={key} active={this.state.activeSlide === key} {...slide}/>)
                    }
                </div>
                <div className="triggers">
                    <ul>
                        <li className={this.state.activeSlide === 0 && 'active'}></li>
                        <li className={this.state.activeSlide === 1 && 'active'}></li>
                        <li className={this.state.activeSlide === 2 && 'active'}></li>
                    </ul>
                    <button onClick={this.onContinueClick}>Continue</button>
                </div>
            </div>
        )
    }
}

export default withRouter(Welcome)
import React from 'react'
import './wallet.styles.scss'
import iconFundWallet from '../../../../assets/icons/wallet-fund.svg'
import iconNaira from '../../../../assets/icons/naira.svg'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import Service from './wallet.service'
import { connect } from 'react-redux'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class Wallet extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadWallet()
    }

    render() {
        return (
            <>
                {this.state.loadingWallet && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="wallet">
                    <div>
                        <p>Wallet Balance</p>
                        <h2>
                            <img alt="naira" src={iconNaira} />
                            <NumberFormat value={this.state.wallet.balance/100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </h2>
                    </div>
                    <Link to="/wallet"><img alt="fund wallet" src={iconFundWallet} /></Link>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Wallet)
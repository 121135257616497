import React from 'react'
import './brand.styles.scss'

const Brand = props => {
    return (
        <div className="brand">
            <h2>{props.name}</h2>
            <h5>{props.description}</h5>
            <img alt="brand" src={props.imageUrl}/>
        </div>
    )
}

export default Brand
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Orders from './orders/orders.page'
import Details from './details/details.page'
import './common.styles.scss'

const Controller = () => {
    return (
        <div className="order">
            <Switch>
                <Route path="/order/all" component={Orders} />
                <Route path="/order/:id" component={Details} />
            </Switch>
        </div>
    )
}

export default Controller
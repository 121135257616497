import React from 'react'
import iconClose from '../../assets/icons/close.svg'
import './confirmation.styles.scss'

const Confirmation = props => {
    return (
        <div className="delete-confirmation">
            <img alt="close" src={iconClose} onClick={props.onClose}/>
            <div>
                <h4>{props.title}</h4>
                <div className="triggers">
                    <button onClick={props.onYes}>Yes</button>
                    <button onClick={props.onNo}>No</button>
                </div>
            </div>
        </div>
    )
}

export default Confirmation
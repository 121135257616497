import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Header from '../../../../components/header/header.component'
import Service from './deliverable-items.service'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import './deliverable-items.styles.scss'
import Brand from './components/brand/brand.component'
import Varieties from './varieties/varieties.component'
import CartService from '../../cart.service'
import DeliverableItem from './deliverable-item/deliverable-item.component'

class DeliverableItems extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.cart = CartService()
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Marketplace | Items | ' + this.props.match.params.sku
        this.service.loadDeliverableItems()
    }

    onVarietyChanged = variety => {
        this.setState({
            ...this.state,
            currentVariety: variety
        })
    }

    onItemSelected = item => {
        item.quantity = 1
        sessionStorage.setItem("currentItem", JSON.stringify(item))
        this.props.history.push('/marketplace/:sku/details')
    }

    render() {
        return (
            <>
                <Header title="Marketplace" backUrl="/marketplace" cartBox={true} />
                <div className="deliverable-items">
                    {this.state.loadingDeliverableItems && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.error} />}
                    {
                        this.state.deliverableItems.length > 0 &&
                        <>
                            <Brand {...this.state.deliverableItems[0].deliverable}/>
                            <Varieties varieties={this.state.varieties} currentVariety={this.state.currentVariety} onChange={this.onVarietyChanged} />
                            <label>Select Item</label>
                        </>
                    }
                    <div className="deliverable-items">
                        {
                            this.state.deliverableItems
                                .filter(deliverableItem => deliverableItem.category === this.state.currentVariety)
                                .map((deliverableItem, key) => <DeliverableItem key={key} {...deliverableItem} onClick={() => this.onItemSelected(deliverableItem)} />)
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DeliverableItems))
import React from "react"
import iconMtn from '../../../../../assets/icons/mtn-white.svg'
import iconAirtel from '../../../../../assets/icons/airtel-white.svg'
import iconGlo from '../../../../../assets/icons/glo-white.svg'
import icon9Mobile from '../../../../../assets/icons/9mobile-white.svg'
import './beneficiary-info.styles.scss'

const BeneficiaryInfo = props => {
    let iconInfo = getIcon(props.network)
    return (
        <div className="beneficiary-info">
            <div className={iconInfo.class}>
                <img alt="icon" src={iconInfo.src} />
            </div>
            <div>
                <h4>{props.name}</h4>
                <p>
                    {props.number}
                </p>
            </div>
        </div>
    )
}

const getIcon = network => {
    switch (network) {
        case 0:
            return {
                class: "mtn",
                src: iconMtn
            }
        case 1:
            return {
                class: "airtel",
                src: iconAirtel
            }
        case 2:
            return {
                class: "glo",
                src: iconGlo
            }
        default:
            return {
                class: "_9mobile",
                src: icon9Mobile
            }
    }
}

export default BeneficiaryInfo
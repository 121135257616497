import axios from "axios"

export const BUILD = 33
export const DEBUG = false
//android
//export const DeviceType = 0
//ios
//export const DeviceType = 1
//web
export const DeviceType = 2

export const API_ENDPOINT = DEBUG ? "http://localhost:8080" : "https://api2.dailyruns.ng"

axios.defaults.headers.common = {
    "build": BUILD
}

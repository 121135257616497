import React from "react"
import iconClose from '../../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../../components/dialog/dialog.component"
import './area-chooser.styles.scss'
import Service from "./area-chooser.service"
import Area from "./area/area.component"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Loading from '../../../../../../../components/loading/loading.component'

class AreaChooser extends React.Component {

    constructor(props){
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        this.service.loadAreas()
    }

    render(){
        return (
            <Dialog>
                <div className="area-chooser">
                {this.state.loadingAreas && <Dialog><Loading /></Dialog>}
                    <h3>
                        <span>Select Area</span>
                        <img alt="close" src={iconClose} onClick={this.props.onClose} />
                    </h3>
                    <div className="areas">
                        {
                            this.state.areas.map((area, key) => <Area key={key} {...area} onClick={() => this.props.onSelect(area)}/>)
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(AreaChooser))
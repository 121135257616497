import { SET_ACCOUNTS, SET_PLANS, ADD_ACCOUNT } from "./action-types";

export function InternetAddAccount(account) {
    return { type: ADD_ACCOUNT, payload: account }
}

export function InternetSetAccounts(accounts) {
    return { type: SET_ACCOUNTS, payload: accounts }
}

export function InternetSetPlans(plans) {
    return { type: SET_PLANS, payload: plans }
}
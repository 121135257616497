import React from 'react'
import './pages.styles.scss'

const PagesTemplate = (props) => {
    return (
        <>
            <section className="pages">
                <div>
                    <div className="container">
                        {props.children}
                        <footer>
                            <p>© 2022 Skysenx Limited. All rights reserved.</p>
                        </footer>
                    </div>
                </div>
                <div>
                    <div className="shade"></div>
                </div>
            </section>
        </>
    )
}

export default PagesTemplate
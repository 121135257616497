import Requests from "../../../utils/Requests"
import { StringValidationRule } from '../../../data/validation/rules'
import Validation from '../../../data/validation/validation'

const getDefaultState = () => {
    return {
        updating: false,
        currentPassword: "",
        newPassword: "",
        newPassword2: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.currentPassword, (error) => errors.currentPassword = error, { min: { value: 6, error: "Too short" }, max: { value: 40, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.newPassword, (error) => errors.newPassword = error, { min: { value: 6, error: "Too short" }, max: { value: 40, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.newPassword2, (error) => errors.newPassword2 = error, { min: { value: 6, error: "Too short" }, max: { value: 40, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updatePassword = instance => {
    let data = {
        currentPassword: instance.state.currentPassword,
        newPassword: instance.state.newPassword
    }

    Requests(instance.props)
    .post()
    .withUrl("/customer/change-password")
    .withAuthorization(instance.props.auth.authorization)
    .withData(data)
    .then(response => {
        handleUpdatePasswordResponse(instance, response)
    }, error => {
        handleError(instance, "Connection Failed")
    })
}

const handleUpdatePasswordResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "success",
                    text: "Saved"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        updating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onUpdatePassword,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        updatePassword: () => updatePassword(instance)
    }
}

export default Service
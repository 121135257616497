import React from 'react'
import { withRouter } from 'react-router-dom'
import './deliverables.styles.scss'
import Deliverable from './deliverable/deliverable.component'
import Service from './deliverables.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'
import EmptyList from '../../../components/empty-list/empty-list.component'
import SearchBox from '../../../components/inputs/searchbox/searchbox.component'

class Deliverables extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Marketplace | Categories'
        this.onLoadDeliverables()
    }

    onLoadDeliverables = () => {
        this.setState({
            ...this.state,
            loadingDeliverables: true,
            showError: false
        }, () => this.service.loadDeliverables())
    }

    onSearchChanged = e => {
        this.setState({
            ...this.state,
            searchKeywords: e.target.value 
        })
    }

    render() {
        return (
            <div className="deliverables">
                {this.state.loadingDeliverables && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                <Header title="Marketplace" backUrl="/dashboard"  cartBox={true}/>
                <div className="records">
                <SearchBox name="search" placeholder="Search items: bread, milk, shawarma" onChange={this.onSearchChanged} />
                    {
                        this.state.deliverables.filter(deliverable => deliverable.name.toLowerCase().includes(this.state.searchKeywords.toLowerCase()) || deliverable.description.toLowerCase().includes(this.state.searchKeywords.toLowerCase())).map((deliverable, key) => <Deliverable key={key} {...deliverable} />)
                    }
                </div>
                {
                    !this.state.loadingDeliverables && this.state.deliverables.length === 0 && <EmptyList title="oops! no items found" />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Deliverables))
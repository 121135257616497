import Requests from '../../../utils/Requests'

const getDefaultState = instance => {
    return {
        loadingDecoders: false,
        decoders: [],
        showError: false,
        alertError: {}
    }
}

const loadDecoders = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/cabletv/decoders")
        .withAuthorization(instance.props.auth.authorization)
        .withData({maxSize: 500, offset: 0})
        .then(response => {
            handleLoadDecodersResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDecodersResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDecoders: false,
                decoders: response.data.decoders
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingDecoders: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDecoders,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const DecodersWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDecoders: () => loadDecoders(instance)
    }
}

export default DecodersWorker
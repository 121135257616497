import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingEvents: false,
        searchKeywords: "",
        events: [],
        showError: false,
        alertError: {}
    }
}

const loadEvents = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/services/ticketing/events")
        .withAuthorization(instance.props.auth.authorization)
        .withData({size: 30, offset: 0, keyword: instance.state.searchKeywords})
        .then(response => {
            handleLoadEventsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadEventsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingEvents: false,
                events: response.data.events
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingEvents: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadEvents,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const EventsWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadEvents: () => loadEvents(instance)
    }
}

export default EventsWorker

const init = () => {
    let cart = []
    sessionStorage.setItem("cart", JSON.stringify(cart));
    return cart
}

const items = () => {
    let cart = sessionStorage.getItem("cart")
    if(!cart){
        return init()
    }
    return JSON.parse(cart);
}

const itemsWeight = () => {
    let cart = items()
    let totalWeight = 0

    for(let i = 0; i < cart.length; i++){
        totalWeight += cart[i].weight * cart[i].quantity
    }

    return totalWeight
}

const save = cart => {
    sessionStorage.setItem("cart", JSON.stringify(cart));
}

const add = item => {
    let cart = items()
    let index = indexOf(cart, item)

    if(index === -1){
        cart.push(item)
    }

    save(cart)
}

const indexOf = (cart, item) => {
    for(let i = 0; i < cart.length; i++){
        if(item.id === cart[i].id){
            return i
        }
    }
    return -1
}

const remove = index => {
    let cart = items()
    delete cart[index]
    save(cart)
}

const clear = () => {
    save([])
}

const Service = () => {
    return {
        items: () => items(),
        add: (item) => add(item),
        remove: (index) => remove(index),
        save: (cart) => save(cart),
        clear: () => clear(),
        itemsWeight: () => itemsWeight(),
        init: () => init()
    }
}

export default Service
import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        deleting: false,
        password: "",
        showPasswordDialog: false,
        showError: false,
        alertError: {}
    }
}

const deleteAccount = instance => {

    let data = {
        password: instance.state.password
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleDeleteAccountResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeleteAccountResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            sessionStorage.removeItem("auth")
            window.location = '/';
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        deleting: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDeleteAccount,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deleteAccount: () => deleteAccount(instance)
    }
}

export default Service
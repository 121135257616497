import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Form from './form.page'
import FormHelper from '../../../utils/FormHelper'
import Worker from './worker'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'

class Forgot extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Auth | Forgot Password'
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.onReset()
    }

    onReset = () => {
        this.worker.isValid() && this.setState({
            ...this.state,
            processing: false,
            showError: false
        }, () => this.worker.reset())
    }

    getVerificationComponent = () => {
        return (
            <div className="complete">
                <h3>Password Reset</h3>
                <div className="flag success">
                    Reset link sent to your email
                </div>
                <br />
                <p>Click <Link to="/auth/login">here</Link> to login when done</p>
            </div>
        )
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return this.getVerificationComponent()
            default:
                return <div className="card login">
                    {this.state.processing && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    <h3>Reset Password</h3>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state} />
                </div>
        }
    }

    render() {
        return (
            <>
                <Header title="" backUrl="/auth/login" />
                {this.getCurrentTab()}
            </>
        )
    }
}

export default connect(null)(withRouter(Forgot))
import React from 'react'
import './bouquet.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../assets/icons/arrow-bottom-light.svg'
import BouquetChooser from './bouquet-chooser/bouquet-chooser.component'

class Bouquet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showBouquetChooser: false
        }
    }

    toggleShowBouquetChooser = () => {
        this.setState({
            ...this.state,
            showBouquetChooser: !this.state.showBouquetChooser
        })
    }

    onSelect = bouquet => {
        this.props.onChange(bouquet)
        this.toggleShowBouquetChooser()
    }

    render() {
        return (
            <>
                {this.state.showBouquetChooser && <BouquetChooser decoder={this.props.decoder} onSelect={this.onSelect} onClose={this.toggleShowBouquetChooser} />}
                <div className="bouquet" onClick={this.toggleShowBouquetChooser}>
                    <h4>{this.props.bouquet.name}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(Bouquet)
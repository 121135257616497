import React from 'react'
import { Switch, Route } from "react-router-dom"
import Events from './events/events.page'
import Order from './order/order.page'
import CreateOrder from './order/create/create.page'

const TicketingController = () => {
    return (
        <div className="ticketing">
            <Switch>
                <Route path="/ticketing/order/create" component={CreateOrder} />
                <Route path="/ticketing/:eventCode" component={Order} />
                <Route path="/ticketing" component={Events} />
            </Switch>
        </div>
    )
}

export default TicketingController
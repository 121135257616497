import React from "react"
import './get-password.styles.scss'
import PasswordInput from '../../../../../components/inputs/password/password.component'
import { StringValidationRule } from '../../../../../data/validation/rules'
import Validation from '../../../../../data/validation/validation'

class GetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: "",
            errors: {},
            flag: {
                type: "",
                text: ""
            }
        }
    }

    onChange = e => {
        this.setState({
            ...this.state,
            password: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault()
        this.isValid() && this.props.onPasswordReceived(this.state.password)
    }

    isValid = () => {
        let validation = new Validation()
        let errors = this.state.errors
        validation.addValidationRule(StringValidationRule, this.state.password, (error) => errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
        let validate = validation.validate()
        let stateUpdate = {
            errors: errors,
            flag: {
                type: validate ? "" : "error",
                text: validate ? "" : "Validation Failed"
            }
        }
        this.setState({
            ...this.state,
            ...stateUpdate
        })
        return validate
    }

    onDoNotDeleteClicked = () => window.location = '/dashboard'

    render() {
        return (
            <div className="get-password">
                <h4>Confirm Account Deletion</h4>
                <form onSubmit={this.onSubmit}>
                    <div className="form-input">
                        <label>Password</label>
                        <PasswordInput onChange={this.onChange} placeholder="Enter Password" />
                        <span className="error">{this.state.errors.password}</span>
                    </div>
                    <button className="btn action">Delete Account</button>
                </form>
                <button className="btn action continue" onClick={this.onDoNotDeleteClicked}>Do Not Delete</button>
            </div>
        )
    }
}

export default GetPassword
import Requests from '../../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingDeliverableItems: true,
        deliverableItems: [],
        varieties: [],
        currentVariety: "",
        showError: false,
        alertError: {}
    }
}

const loadDeliverableItems = instance => {
    let data = {
        deliverableSku: instance.props.match.params.sku, 
        maxSize: 20, 
        offset: 0
    }
    Requests(instance.props)
        .post()
        .withUrl("/customer/marketplace/deliverable-items")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadDeliverableItemsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliverableItemsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            let varieties = getVarieties(response.data.deliverableItems)
            instance.setState({
                ...instance.state,
                loadingDeliverableItems: false,
                deliverableItems: response.data.deliverableItems,
                varieties: varieties,
                currentVariety: varieties.length > 0?varieties[0]:""
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const getVarieties = deliverableItems => {
    let varieties = []
    for(let i = 0; i < deliverableItems.length; i++){
        if(!varieties.includes(deliverableItems[i].category)){
            varieties.push(deliverableItems[i].category)
        }
    }
    return varieties
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingDeliverableItems: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDeliverableItems,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDeliverableItems: () => loadDeliverableItems(instance)
    }
}

export default Service
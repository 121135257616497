import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Service from './verify.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class Verify extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Auth | Verify Account'
        this.service.verify()
    }

    onVerify = () => {
        this.setState({
            ...this.state,
            verifying: true
        }, () => this.service.verify())
    }

    getVerifiedComponent = () => {
        return (
            <div className="complete">
                <h3>Verification Successful</h3>
                <br />
                <p>Please click <Link to="/auth/login">here</Link> to login</p>
            </div>
        )
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return this.getVerifiedComponent()
            default:
                return <div className="verify">
                    {this.state.verifying && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                </div>
        }
    }

    render() {
        return this.getCurrentTab()
    }
}

export default withRouter(Verify)
import React from 'react'
import './renew-cycle.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../../assets/icons/arrow-bottom-light.svg'
import RenewCycleChooser from './renew-cycle-chooser/renew-cycle-chooser.component'

class RenewCycle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showRenewCycleChooser: false
        }
    }

    toggleShowRenewCycleChooser = () => {
        this.setState({
            ...this.state,
            showRenewCycleChooser: !this.state.showRenewCycleChooser
        })
    }

    onSelect = renewCycle => {
        this.props.onChange(renewCycle)
        this.toggleShowRenewCycleChooser()
    }

    render() {
        return (
            <>
                {this.state.showRenewCycleChooser && <RenewCycleChooser onSelect={this.onSelect} onClose={this.toggleShowRenewCycleChooser} />}
                <div className="renew-cycle" onClick={this.toggleShowRenewCycleChooser}>
                    <h4>{this.props.renewCycle.label}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(RenewCycle)
import React from 'react'
import './quantity.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../assets/icons/arrow-bottom-light.svg'
import QuantityChooser from './quantity-chooser/quantity-chooser.component'

class Quantity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showQuantityChooser: false
        }
    }

    toggleShowQuantityChooser = () => {
        this.setState({
            ...this.state,
            showQuantityChooser: !this.state.showQuantityChooser
        })
    }

    onSelect = quantity => {
        this.props.onChange(quantity)
        this.toggleShowQuantityChooser()
    }

    render() {
        return (
            <>
                {this.state.showQuantityChooser && <QuantityChooser quantities={this.props.quantities} onSelect={this.onSelect} onClose={this.toggleShowQuantityChooser} />}
                <div className="quantity" onClick={this.toggleShowQuantityChooser}>
                    <h4>{this.props.quantity.label}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(Quantity)
import React from 'react'
import './decoder-type.styles.scss'
import { withRouter } from 'react-router-dom'
import iconDstv from '../../../../../../assets/icons/dstv-white.svg'
import iconGotv from '../../../../../../assets/icons/gotv.svg'
import iconStartimes from '../../../../../../assets/icons/startimes.svg'
import iconArrowDown from '../../../../../../assets/icons/arrow-bottom.svg'
import ProviderChooser from './provider-chooser/provider-chooser.component'

class DecoderType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showProviderChooser: false
        }
    }

    toggleShowProviderChooser = () => {
        this.setState({
            ...this.state,
            showProviderChooser: !this.state.showProviderChooser
        })
    }

    getIconInfo = () => {
        switch (this.props.type) {
            case 0:
                return {
                    name: "DSTV",
                    class: "dstv",
                    src: iconDstv
                }
            case 1:
                return {
                    name: "GOTV",
                    class: "gotv",
                    src: iconGotv
                }
            default:
                return {
                    name: "STARTIMES",
                    class: "startimes",
                    src: iconStartimes
                }
        }
    }

    onSelect = type => {
        this.props.onChange({
            target: {
                name: "type",
                value: type
            }
        })
        this.toggleShowProviderChooser()
    }

    render() {
        let iconInfo = this.getIconInfo()
        return (
            <>
                {this.state.showProviderChooser && <ProviderChooser onSelect={this.onSelect} onClose={this.toggleShowProviderChooser} />}
                <div className="decoder-type" onClick={this.toggleShowProviderChooser}>
                    {
                        this.props.type !== "" && <>
                            <div className={iconInfo.class}>
                                <img alt="icon" src={iconInfo.src} />
                            </div>
                        </>
                    }
                    <h4>{this.props.type === "" ? "Select Cable TV Provider" : iconInfo.name}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(DecoderType)
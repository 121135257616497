import Requests from "../../../../../../utils/Requests"

const getDefaultState = instance => {
    return {
        showError: false,
        alertError: {}
    }
}

const deleteAccount = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/internet/skysenx/client/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData({ id: instance.props.account.id })
        .then(response => {
            handleDeleteAccountResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeleteAccountResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.onDeleted()
            break
        default:
            handleError(instance, "An error occurred")
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDeleteAccount,
            onClose: () => instance.props.history.push('/internet/skysenx')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deleteAccount: () => deleteAccount(instance)
    }
}

export default Service
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingDeliverables: false,
        searchKeywords: "",
        deliverables: [],
        showError: false,
        alertError: {}
    }
}

const loadDeliverables = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/marketplace/deliverables")
        .withAuthorization(instance.props.auth.authorization)
        .withData({maxSize: 20, offset: 0})
        .then(response => {
            handleLoadDeliverablesResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliverablesResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDeliverables: false,
                deliverables: response.data.deliverables
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingDeliverables: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDeliverables,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const DeliverablesWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDeliverables: () => loadDeliverables(instance)
    }
}

export default DeliverablesWorker
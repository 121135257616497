import React from 'react'
import './plan.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../../assets/icons/arrow-bottom-light.svg'
import PlanChooser from './plan-chooser/plan-chooser.component'
import NumberFormat from 'react-number-format'

class Plan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPlanChooser: false
        }
    }

    toggleShowPlanChooser = () => {
        this.setState({
            ...this.state,
            showPlanChooser: !this.state.showPlanChooser
        })
    }

    onSelect = plan => {
        this.props.onChange(plan)
        this.toggleShowPlanChooser()
    }

    render() {
        return (
            <>
                {this.state.showPlanChooser && <PlanChooser account={this.props.account} onSelect={this.onSelect} onClose={this.toggleShowPlanChooser} />}
                <div className="plan" onClick={this.toggleShowPlanChooser}>
                    <h4>{this.props.plan !== null? this.props.plan.name: "Choose a Plan"} {this.props.plan !== null && <NumberFormat value={this.props.plan.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span>{value}</span>} />}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(Plan)
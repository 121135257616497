import React from 'react'
import { Link } from 'react-router-dom'
import iconHome from '../../../../assets/icons/home.svg'
import iconHomeDark from '../../../../assets/icons/home-dark.svg'
import iconOrder from '../../../../assets/icons/order.svg'
import iconOrderDark from '../../../../assets/icons/order-dark.svg'
import iconWallet from '../../../../assets/icons/wallet.svg'
import iconWalletDark from '../../../../assets/icons/wallet-dark.svg'
import iconSettings from '../../../../assets/icons/settings.svg'
import iconSettingsDark from '../../../../assets/icons/settings-dark.svg'

export const Item = (props) => {
    return (
        <li className={props.active ? 'item active' : 'item'}>
            <Link onClick={props.onSubItemClick} to={props.url}>
                <img src={getLogo(props)} alt="" />
                <span>{props.text}</span>
            </Link>
        </li>
    )
}

const getLogo = props => {
    switch (props.text) {
        case "Orders":
            return props.active ? iconOrderDark : iconOrder
        case "Wallet":
            return props.active ? iconWalletDark : iconWallet
        case "Settings":
            return props.active ? iconSettingsDark : iconSettings
        default:
            return props.active ? iconHomeDark : iconHome
    }
}

export default Item
import React from 'react'
import { withRouter } from 'react-router'
import iconLogo from '../../assets/icons/logo.svg'
import Device from '../../utils/Device'
import './splash.styles.scss'
import { BUILD } from '../../config'

class Splash extends React.Component {

    componentDidMount(){
        document.title = 'Splash'
        Device.saveReferralUrl()
        let interval = window.setInterval(() => {
            window.clearInterval(interval)
            !Device.getAuth()? Device.getInfo().type === Device.types.web? this.props.history.push('/dashboard'): this.props.history.push('/welcome'): this.props.history.push('/dashboard')
        }, 3000)
    }

    render(){
        return(
            <div className="splash">
                <img alt="" src={iconLogo} />
                <p className='brand'>
                    from
                    <strong>Skysenx</strong>
                </p>
                <p className='build'>build 20{BUILD}</p>
            </div>
        )
    }
}

export default withRouter(Splash)
import React from 'react'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Header from '../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import DecoderInfo from './decoder-info/decoder-info.component'
import DecoderBouquet from './decoder-bouquet/decoder-bouquet.component'
import Bouquet from './bouquet/bouquet.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Cabletv | Prepare Order';
        this.service.loadDecoder()
    }

    onLoadDecoder = () => this.setState({
        ...this.state,
        loading: true
    }, this.service.loadDecoder)

    toggleShowCreateOrder = () => {
        this.setState({
            ...this.state,
            showCreateOrder: !this.state.showCreateOrder
        })
    }

    onBouquetChange = bouquet => {
        let newBouquet = (bouquet.code === this.state.decoderBouquet.code && this.state.decoderBouquet.amount > 0) ? this.state.decoderBouquet: bouquet
        this.setState({
            ...this.state,
            bouquet: newBouquet
        })
    }

    onPay = () => {
        if (true) {
            let cabletvOrder = {
                decoder: this.state.decoder,
                bouquet: this.state.bouquet,
                renewal: this.state.bouquet.code === this.state.decoderBouquet.code
            }

            sessionStorage.setItem("cabletvOrder", JSON.stringify(cabletvOrder))
            this.props.history.push('/cabletv/order/create')
        }
    }

    render() {
        if(this.state.decoder === null){
            return <Dialog><Loading /></Dialog>
        }
        return (
            <>
                <Header title="Cable TV Subscription" backUrl="/cabletv" />
                <div className="order">
                    {this.state.loading && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    {this.state.decoder !== null && <div className="summary"><DecoderInfo {...this.state.decoder} /><DecoderBouquet decoder={this.state.decoder} /></div>}
                    <br />
                    <div className="form-control">
                        <label>Select to change Bouquet</label>
                        <Bouquet bouquet={this.state.bouquet} decoder={this.state.decoder} onChange={this.onBouquetChange}/>
                    </div>

                    <div className="form-control">
                        <label>Amount</label>
                        <div className="renewal">
                            <NumberFormat value={this.state.bouquet.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
                        </div>
                    </div>

                    <NumberFormat value={this.state.bouquet.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <button onClick={this.onPay} className="btn action">Pay - {value}</button>} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Order))
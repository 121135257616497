import React from 'react'
import { withRouter } from 'react-router-dom'
import './beneficiaries.styles.scss'
import Header from '../../../../components/header/header.component'
import Beneficiary from './beneficiary/beneficiary'
import Service from './beneficiaries.service'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import '../common.styles.scss'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import SubHeader from '../../../../components/sub-header/sub-header.component'
import EmptyList from '../../../../components/empty-list/empty-list.component'
import DeleteBeneficiary from './components/delete/delete.component'

class Beneficiaries extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Mobile Topup | Saved Beneficiaries'
        this.onLoadBeneficiaries()
    }

    onLoadBeneficiaries = () => {
        this.setState({
            ...this.state,
            loadingBeneficiaries: true,
            showError: false
        }, () => this.service.loadBeneficiaries())
    }

    onBeneficiarySelected = beneficiary => {
        sessionStorage.setItem("currentBeneficiary", JSON.stringify(beneficiary))
        this.props.history.push('/utility/airtime/order')
    }

    onDeleteBeneficiary = beneficiary => {
        this.setState({
            ...this.state,
            showDeleteBeneficiary: true,
            beneficiaryToDelete: beneficiary
        })
    }

    onBeneficiaryDeleted = () => {
        let beneficiaries = this.state.beneficiaries.filter(beneficiary => beneficiary.id !== this.state.beneficiaryToDelete.id)
        this.setState({
            ...this.state,
            beneficiaries: beneficiaries,
            showDeleteBeneficiary: false,
            beneficiaryToDelete: null
        })
    }

    onCancelDeleteBeneficiary = () => {
        this.setState({
            ...this.state,
            showDeleteBeneficiary: false,
            beneficiaryToDelete: null
        })
    }
    
    render() {
        return (
            <div className="beneficiaries">
                {this.state.loadingBeneficiaries && <Dialog><Loading></Loading></Dialog>}
                {this.state.showDeleteBeneficiary && <Dialog><DeleteBeneficiary beneficiary={this.state.beneficiaryToDelete} onDeleted={this.onBeneficiaryDeleted} onCancel={this.onCancelDeleteBeneficiary}/></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error}/>}
                <Header title="Mobile Top Up" backUrl="/dashboard"/>
                <SubHeader title="Beneficiaries" url="/utility/airtime/order"/>
                <div className="records">
                    {
                        this.state.beneficiaries.map((beneficiary, key) => <Beneficiary onClick={() => this.onBeneficiarySelected(beneficiary)} onDeleteClick={() => this.onDeleteBeneficiary(beneficiary)} key={key} {...beneficiary}/>)
                    }
                </div>
                {
                    !this.state.loadingBeneficiaries && this.state.beneficiaries.length === 0 && <EmptyList title="oops! no saved beneficiary"/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        airtime: state.airtime,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Beneficiaries))
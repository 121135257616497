import React from 'react'
import Header from '../../../components/header/header.component'
import './help.styles.scss'
import iconHelp from '../../../assets/icons/help.svg'

const Contact = () => {
    document.title = 'Settings | Help'
    return (
        <>
            <Header title="Get Help" backUrl="/settings" />
            <div className="help">
                <img alt="help" src={iconHelp} />
                <h4>Hi, need help today?</h4>
                <br/>
                <p>Call Centre</p>
                <a href="tel:09086000820">0908 600 0820</a>
                <p>Or send us an email</p>
                <a href="mailto:support@dailyruns.ng">support@dailyruns.ng</a>
            </div>
        </>
    )
}

export default Contact
import React from "react"
import iconClose from '../../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../../components/dialog/dialog.component"
import './provider-chooser.styles.scss'
import iconMtn from '../../../../../../../assets/icons/mtn-white.svg'
import iconAirtel from '../../../../../../../assets/icons/airtel-white.svg'
import iconGlo from '../../../../../../../assets/icons/glo-white.svg'
import icon9Mobile from '../../../../../../../assets/icons/9mobile-white.svg'

const ProviderChooser = props => {
    return (
        <Dialog>
            <div className="provider-chooser">
                <h3>
                    <span>Select Network Provider</span>
                    <img alt="close" src={iconClose} onClick={props.onClose} />
                </h3>
                <div className="providers">
                    <div className="provider" onClick={() => props.onSelect(0)}>
                        <div className="mtn">
                            <img alt="icon" src={iconMtn} />
                        </div>
                        <h4>Mtn</h4>
                    </div>
                    <div className="provider" onClick={() => props.onSelect(1)}>
                        <div className="airtel">
                            <img alt="icon" src={iconAirtel} />
                        </div>
                        <h4>Airtel</h4>
                    </div>
                    <div className="provider" onClick={() => props.onSelect(2)}>
                        <div className="glo">
                            <img alt="icon" src={iconGlo} />
                        </div>
                        <h4>Glo</h4>
                    </div>
                    <div className="provider" onClick={() => props.onSelect(3)}>
                        <div className="_9mobile">
                            <img alt="icon" src={icon9Mobile} />
                        </div>
                        <h4>9Mobile</h4>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ProviderChooser
import React from 'react'
import './delivery-option.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../assets/icons/arrow-bottom.svg'
import DeliveryOptionChooser from './delivery-option-chooser/delivery-option-chooser.component'
import NumberFormat from 'react-number-format'

class DeliveryOption extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDeliveryOptionChooser: false
        }
    }

    toggleShowDeliveryOptionChooser = () => {
        this.setState({
            ...this.state,
            showDeliveryOptionChooser: !this.state.showDeliveryOptionChooser
        })
    }

    onSelect = deliveryOption => {
        this.props.onChange(deliveryOption)
        this.toggleShowDeliveryOptionChooser()
    }

    getCurrentDeliveryOption = () => {
        if(this.props.deliveryOption === null){
            return <h4>Select Delivery Option</h4>
        }

        return <h4>{this.props.deliveryOption.name}  <NumberFormat value={this.props.deliveryOption.amount/100} displayType={'text'} thousandSeparator={true} renderText={value => <span>N{value}</span>} /></h4>
    }

    render() {
        return (
            <>
                {this.state.showDeliveryOptionChooser && <DeliveryOptionChooser itemsWeight={this.props.itemsWeight} areaId={this.props.areaId} onSelect={this.onSelect} onClose={this.toggleShowDeliveryOptionChooser} />}
                <div className="delivery-option" onClick={this.toggleShowDeliveryOptionChooser}>
                    <h4>{this.getCurrentDeliveryOption()}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(DeliveryOption)
import React from "react"
import iconClose from '../../../../../assets/icons/close.svg'
import Dialog from "../../../../../components/dialog/dialog.component"
import './quantity-chooser.styles.scss'
import Quantity from "./quantity/quantity.component"
import { withRouter } from "react-router-dom"

const QuantityChooser = props => {
    return (
        <Dialog>
            <div className="quantity-chooser">
                <h3>
                    <span>Select Quantity</span>
                    <img alt="close" src={iconClose} onClick={props.onClose} />
                </h3>
                <div className="quantities">
                    {
                        props.quantities.map((quantity, key) => <Quantity key={key} {...quantity} onClick={() => props.onSelect(quantity)} />)
                    }
                </div>
            </div>
        </Dialog>
    )
}

export default withRouter(QuantityChooser)
import React from 'react'
import Distributor from './distributor/distributor.component'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="distributor">
                    <label>Power Distributor</label>
                    <Distributor distributor={props.distributor} onChange={props.onChange} />
                    <span className="error">{props.errors.type}</span>
                </div>
                <div className="row" id="type">
                    <label>Meter Type</label>
                    <div className="form-input">
                        <select name="type" onChange={props.onChange} value={props.type}>
                            <option value="">Meter Type</option>
                            <option value="0">Prepaid</option>
                            <option value="1">Postpaid</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                <div className="row" id="number">
                    <label>Meter Number</label>
                    <div className="form-input">
                        <input type="text" name="number" placeholder="Meter Number" value={props.number} onChange={props.onChange} />
                        <span className="error">{props.errors.number}</span>
                    </div>
                </div>
                <div className="row" id="alias">
                    <label>Nickname</label>
                    <div className="form-input">
                        <input type="text" name="alias" placeholder="Nickname (eg Home, Office, etc)" value={props.alias} onChange={props.onChange} />
                        <span className="error">{props.errors.alias}</span>
                    </div>
                </div>
            </div>
            <button className="btn action" type="submit">Save & Continue</button>
        </form>
    )
}

export default Form
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingWallet: true,
        loadingDedicatedNuban: true,
        showCreateDedicatedNuban: false,
        wallet: {
            balance: 0
        },
        dedicatedNuban: null,
        showError: false,
        alertError: {}
    }
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                wallet: response.data.wallet
            })
            break
        default:
            alert(response.status.message)
    }
}

const loadDedicatedNuban = instance => {

    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                dedicatedNuban: response.data.dedicatedNuban
            })
            break
        case 16200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                showCreateDedicatedNuban: true
            })
            break
        default:
            handleError(response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingDedicatedNuban: false,
        loadingWallet: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onReloadClick,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        loadDedicatedNuban: () => loadDedicatedNuban(instance),
        loadWallet: () => loadWallet(instance)
    }
}

export default Service
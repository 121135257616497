import React from "react"
import { Switch, Route } from 'react-router-dom'
import Privacy from './privacy/privacy.page'
import Terms from './terms/terms.page'

const Controller = () => {
    return (
        <div className="static">
            <Switch>
                <Route path="/pages/terms" component={Terms} />
                <Route path="/pages/privacy" component={Privacy} />
            </Switch>
        </div>
    )
}

export default Controller
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule, PhoneValidationRule, StringValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    let beneficiary = getBeneficiaryFromSession()
    return {
        isNewBeneficiary: beneficiary === null,
        ...(beneficiary !== null? beneficiary: createBeneficiary()),
        orderType: 0,
        shouldSavedBeneficiary: false,
        dataBundle: null,
        amount: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        },
        showCreateOrder: false
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    if(instance.state.orderType === 0) {
        validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: 100, error: "Minimum 100" }, max: { value: 1000000, error: "Invalid" } })
    }
    if(instance.state.isNewBeneficiary) {
        validation.addValidationRule(StringValidationRule, instance.state.network, (error) => errors.network = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Too long" } })
        validation.addValidationRule(PhoneValidationRule, instance.state.number, (error) => errors.number = error, { allowNull: false, error: "Invalid"})
    }
    if(instance.state.shouldSavedBeneficiary) {
        validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 3, error: "Too short" }, max: { value: 25, error: "Too long" } })
    }
    let validate = validation.validate()
    if(instance.state.orderType === 1 && instance.state.dataBundle === null){
        errors.dataBundle = "Invalid"
        validate = false
    }
    else{
        errors.dataBundle = ""
    }
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const getBeneficiaryFromSession = () => {
    let beneficiary = sessionStorage.getItem("currentBeneficiary")
    if(typeof beneficiary === 'undefined'){
        window.location = "/utility/airtime";
    }
    else{
        return JSON.parse(beneficiary);
    }
    return null
}

const createBeneficiary = () => {
    return {
        id: "",
        network: "",
        name: "",
        number: ""
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance)
    }
}

export default Service
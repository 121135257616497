import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PasswordInput from '../../../components/inputs/password/password.component'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Firstname</label>
                <input type="text" name="firstname" value={props.firstname} placeholder="Enter your firstname" onChange={props.onChange} />
                <span className="error">{props.errors.firstname}</span>
            </div>
            <div className="form-input">
                <label>Lastname</label>
                <input type="text" name="lastname" value={props.lastname} placeholder="Enter your lastname" onChange={props.onChange} />
                <span className="error">{props.errors.lastname}</span>
            </div>
            <div className="form-input">
                <label>Phone</label>
                <input type="text" name="phone" value={props.phone} placeholder="Enter your phone number" onChange={props.onChange} />
                <span className="error">{props.errors.phone}</span>
            </div>
            <div className="form-input">
                <label>Email</label>
                <input type="email" name="email" value={props.email} placeholder="Enter your email address" onChange={props.onChange} />
                <span className="error">{props.errors.email}</span>
            </div>
            <div className="form-input">
                <label>Password</label>
                <PasswordInput onChange={props.onChange} placeholder="Choose a password" />
                <span className="error">{props.errors.password}</span>
            </div>
            <div className="terms">
                <p>
                    By clicking Continue, I agree to our <Link to="/pages/terms">Terms of Service</Link> and <Link to="/pages/privacy">Privacy Policy</Link>
                </p>
            </div>
            <button className={props.processing ? 'btn action disabled' : 'btn action'}>Continue</button>
        </form>
    )
}

export default withRouter(Form)
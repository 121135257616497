import React from 'react'
import Service from './create.service'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import DedicatedNuban from '../../../../../components/wallet/dedicated-nuban/dedicated-nuban.component'
import './create.styles.scss'
import iconNaira from '../../../../../assets/icons/naira-dark.svg'
import NumberFormat from 'react-number-format'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import CreateDedicatedNuban from '../../../../../components/wallet/create-dedicate-nuban/create-dedicate-nuban.component'
import AlertDialog from '../../../../../components/dialog/alert/alert.component'
import PaymentSuccess from '../../../../../components/payment-success/payment-success.component'
import AccountInfo from '../account-info/account-info.component'
import Header from '../../../../../components/header/header.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Internet | Place Order';
        this.onReloadClick()
    }

    onReloadClick = () => {
        this.setState({
            ...this.state,
            loadingWallet: true,
            showError: false
        }, () => this.service.loadWallet())
    }

    onPlaceOrderClick = () => {
        if (this.state.placingOrder !== true) {
            this.setState({
                ...this.state,
                placingOrder: true,
                showError: false
            }, () => this.service.placeOrder())
        }
    }

    onDedicatedNubanCreated = dedicatedNuban => {
        this.setState({
            ...this.state,
            dedicatedNuban: dedicatedNuban,
            showDedicatedNuban: true,
            showCreateDedicatedNuban: false,
            showError: false
        })
    }

    render() {
        if(this.state.internetOrder === null){
            return <></>
        }
        return (
            <>
                <Header title="Skysenx Internet" backUrl="/internet/skysenx/order" />
                <div className="create-order">
                    {(this.state.loadingWallet || this.state.placingOrder) && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    {this.state.showPaymentSuccess && <PaymentSuccess redirectUrl="/order/all" />}
                    <div className="wallet-info">
                        <p>
                            Wallet Balance:
                        </p>
                        <div>
                            <img src={iconNaira} alt="" />
                            <NumberFormat value={this.state.wallet.balance / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                    </div>
                    <div className="summary">
                        <AccountInfo {...this.state.internetOrder.account} />
                        <br />
                        <div className="info">
                            <span>{this.state.internetOrder.plan.name}</span>
                            <NumberFormat value={this.state.internetOrder.plan.amount/100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                        <div className="info">
                            <span>Renew Cycle</span>
                            <span>x {this.state.internetOrder.renewCycle.value}</span>
                        </div>
                        <div className="info">
                            <span>Total (N)</span>
                            <NumberFormat value={this.service.getTotal() / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                    </div>
                    {this.state.showDedicatedNuban && <>
                        <h5 className="low-fund">Insufficient Fund</h5>
                        <DedicatedNuban {...this.state.dedicatedNuban} />
                        <button className="btn action" onClick={this.onReloadClick}>I have funded my Wallet</button>
                    </>}
                    {this.state.showCreateDedicatedNuban && <CreateDedicatedNuban onCreated={this.onDedicatedNubanCreated} />}
                    {this.state.wallet.balance >= this.service.getTotal() && <>
                        <NumberFormat value={this.service.getTotal() / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <button className="btn action" onClick={this.onPlaceOrderClick}>Use {value}</button>} />
                    </>}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))
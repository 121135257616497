import React from 'react'
import './dedicated-nuban.styles.scss'

const DedicatedNuban = props => {
    return (
        <div className="dedicated-nuban">
            <h2>Add funds to your Wallet Account:</h2>
            <div className="info">
                <p>
                    <span>Bank:</span>
                    {props.bank}
                </p>
                <p>
                    <span>Acct No:</span>
                    {props.number}
                </p>
            </div>
            <div className="info">
                <p>
                    <span>Account Name:</span>
                    {props.name}
                </p>
            </div>
        </div>
    )
}

export default DedicatedNuban
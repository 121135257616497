import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Firstname</label>
                <input type="text" name="firstname" value={props.firstname} placeholder="First Name" onChange={props.onChange} />
                <span className="error">{props.errors.firstname}</span>
            </div>
            <div className="form-input">
                <label>Lastname</label>
                <input type="text" name="lastname" value={props.lastname} placeholder="Last Name" onChange={props.onChange} />
                <span className="error">{props.errors.lastname}</span>
            </div>
            <div className="form-input">
                <label>Phone</label>
                <input type="text" name="phone" value={props.phone} placeholder="Phone Number (WhatsApp)" onChange={props.onChange} />
                <span className="error">{props.errors.phone}</span>
            </div>
            <button className="btn action">Save</button>
        </form>
    )
}

export default withRouter(Form)
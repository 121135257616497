import { AUTH_SAVE, AUTH_CLEAR } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case AUTH_SAVE:
            return save(state, action.payload)
        case AUTH_CLEAR:
            return clear(state)
        default:
            return state
    }
}

const save = (state, payload) => {
    state = payload
    sessionStorage.setItem("auth", JSON.stringify(state))
    return state
}

const clear = state => {
    state = {}
    sessionStorage.removeItem("auth")
    return state
}

export default Reducer
import React from "react"

const RenewCycle = props => {
    return (
        <div className="renew-cycle-select" onClick={props.onClick}>
            <h4>{props.label}</h4>
        </div>
    )
}

export default RenewCycle
import React from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import { connect } from 'react-redux'
import BeneficiaryInfo from './beneficiary-info/beneficiary-info.component'
import FormHelper from '../../../../utils/FormHelper'
import Form from './form/form.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Mobile Topup | Prepare Order'
    }

    onAmountChanged = e => {
        this.setState({
            ...this.state,
            amount: e.target.value
        })
    }

    toggleShowDelete = () => {
        this.setState({
            ...this.state,
            showDelete: !this.state.showDelete
        })
    }

    onOrderTypeChanged = orderType => {
        this.setState({
            ...this.state,
            orderType: orderType,
            dataBundle: null,
            amount: "",
            errors: {},
            flag: {
                type: "",
                text: ""
            }
        })
    }

    onSubmit = e => {
        e.preventDefault()
        if (this.service.isValid()) {
            let mobileTopUpOrder = {
                beneficiary: {
                    id: this.state.id,
                    name: this.state.name,
                    number: this.state.number,
                    network: this.state.network
                },
                isNewBeneficiary: this.state.isNewBeneficiary,
                shouldSavedBeneficiary: this.state.shouldSavedBeneficiary,
                orderType: this.state.orderType,
                dataBundle: this.state.dataBundle,
                amount: this.state.amount
            }

            sessionStorage.setItem("mobileTopUpOrder", JSON.stringify(mobileTopUpOrder))
            this.props.history.push('/utility/airtime/order/create')
        }
    }

    onShouldSaveBeneficiaryChange = () => this.setState({
        ...this.state,
        shouldSavedBeneficiary: !this.state.shouldSavedBeneficiary
    })

    onDataBundleChange = dataBundle => {
        this.setState({
            ...this.state,
            dataBundle: dataBundle,
            amount: dataBundle.amount
        })
    }

    onNetworkChange = e => {
        this.setState({
            ...this.state,
            dataBundle: null,
            network: e.target.value,
            amount: ""
        })
    }

    render() {
        return (
            <>
                <Header title="Mobile Top Up" backUrl="/utility/airtime" />
                <div className="order">

                    <div className="order-types">
                        <span className={this.state.orderType === 0 ? 'active' : ''} onClick={() => this.onOrderTypeChanged(0)}>Airtime</span>
                        <span className={this.state.orderType === 1 ? 'active' : ''} onClick={() => this.onOrderTypeChanged(1)}>Data Bundle</span>
                    </div>
                    <br />
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>

                    {!this.state.isNewBeneficiary && <div className="summary"><BeneficiaryInfo {...this.state} /></div>}

                    <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} isNewBeneficiary={this.state.isNewBeneficiary} onShouldSaveBeneficiaryChange={this.onShouldSaveBeneficiaryChange} orderType={this.state.orderType} onDataBundleChange={this.onDataBundleChange} onNetworkChange={this.onNetworkChange} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        airtime: state.airtime
    }
}

export default connect(mapStateToProps)(withRouter(Order))
import React from 'react'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="network">
                    <div className="form-input">
                        <select name="network" onChange={props.onChange} value={props.type}>
                            <option value="">Choose Network</option>
                            <option value="0">Mtn</option>
                            <option value="1">Airtel</option>
                            <option value="2">Glo</option>
                            <option value="3">9mobile</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                <div className="row" id="number">
                    <div className="form-input">
                        <input type="text" name="number" placeholder="Phone Number" value={props.number} onChange={props.onChange} />
                        <span className="error">{props.errors.number}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-input">
                        <input type="text" name="name" placeholder="Beneficiary Name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
            </div>
            <div className="triggers">
                <button type="submit">Add</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form
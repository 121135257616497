import React from 'react'
import Header from '../../../components/header/header.component'
import Service from './profile.service'
import Form from './form/form.page'
import FormHelper from '../../../utils/FormHelper'
import './profile.styles.scss'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class Profile extends React.Component {

    constructor() {
        super()
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Settings | Profile'
        this.onLoadUser()
    }
    
    onLoadUser = () => this.setState({
        ...this.state,
        showError: false,
        loadingUser: true
    }, () => this.service.loadUser())

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onUpdateUser()
    }

    onUpdateUser = () => this.setState({
        ...this.state,
        showError: false,
        updatingUser: true
    }, () => this.service.updateUser())

    render() {
        return (
            <>
                <Header title="Update Profile" backUrl="/settings"/>
                <div className="profile">
                    {(this.state.loadingUser || this.state.updatingUser) && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                    <br />
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} {...this.state} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Profile))
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashbaord from './dashboard/dashboard.page'
import './common.styles.scss'

const Controller = () => {
    return (
        <div className="wallet">
            <Switch>
                <Route exact path="/wallet" component={Dashbaord} />
            </Switch>
        </div>
    )
}

export default Controller
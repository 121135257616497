import React from 'react'
import './data-bundle.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../../../assets/icons/arrow-bottom.svg'
import DataBundleChooser from './data-bundle-chooser/data-bundle-chooser.component'

class DataBundle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDataBundleChooser: false
        }
    }

    toggleShowDataBundleChooser = () => {
        this.setState({
            ...this.state,
            showDataBundleChooser: !this.state.showDataBundleChooser
        })
    }

    onSelect = dataBundle => {
        this.props.onChange(dataBundle)
        this.toggleShowDataBundleChooser()
    }

    render() {
        return (
            <>
                {this.state.showDataBundleChooser && <DataBundleChooser network={this.props.network} onSelect={this.onSelect} onClose={this.toggleShowDataBundleChooser} />}
                <div className="data-bundle" onClick={this.toggleShowDataBundleChooser}>
                    <h4>{this.props.dataBundle !== null ? this.props.dataBundle.name : "Select Data Bundle"}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(DataBundle)
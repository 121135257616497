import { PhoneValidationRule, StringValidationRule } from '../../../data/validation/rules'
import Validation from '../../../data/validation/validation'
import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingUser: false,
        updatingUser: false,
        firstname: "",
        lastname: "",
        phone: "",
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadUser = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadUserResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadUserResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingUser: false,
                firstname: response.data.customer.firstname,
                lastname: response.data.customer.lastname,
                phone: response.data.customer.phone
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => errors.phone = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateUser = instance => {
    let data = {
        firstname: instance.state.firstname.trim(),
        lastname: instance.state.lastname.trim(),
        phone: instance.state.phone.trim()
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleUpdateUserResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUpdateUserResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updatingUser: false,
                flag: {
                    type: "success",
                    text: "Saved"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingUser: false,
        updatingUser: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.state.loadingUser? instance.onLoadUser:instance.onUpdateUser,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        updateUser: () => updateUser(instance),
        loadUser: () => loadUser(instance)
    }
}

export default Service
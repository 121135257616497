import React from "react"
import NumberFormat from "react-number-format"

const Plan = props => {

    let getPeriod = name => {
        if(name.includes('247')){
            return "24 hour"
        }
        else if(name.includes('Day')){
            return "7am - 8pm"
        }
        else if(name.includes('Nite')){
            return "7pm - 7am"
        }
        else {
            return "24 hour"
        }
    }

    return (
        <div className={props.isCurrentPlan? 'plan-select current': 'plan-select'} onClick={props.onClick}>
            <h5>{props.name}</h5>
            <NumberFormat value={props.amount/100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>{value}</strong>} />
            <p>{getPeriod(props.name)}</p>
        </div>
    )
}

export default Plan
import React from 'react'
import './tag.styles.scss'

const Tag = props => {
    return (
        <div className='tag'>
            <label>{props.label}</label>
            <input type="text" name={props.name} placeholder={"Optional name, e.g John"} value={props.value} maxlength="15" onChange={props.onChange}/>
        </div>
    )
}

export default Tag
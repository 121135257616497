import Requests from '../../../../../utils/Requests'
import Validation from '../../../../../data/validation/validation'
import { PhoneValidationRule, StringValidationRule } from '../../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        name: "",
        number: "",
        network: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.network, (error) => errors.network = error, { min: { value: 1, error: "1" }, max: { value: 1, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 40, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.number, (error) => errors.number = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addBeneficiary = instance => {

    let data = {
        network: Number(instance.state.network),
        name: instance.state.name,
        number: instance.state.number.trim()
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/airtime/beneficiary/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleAddBeneficiaryResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleAddBeneficiaryResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.history.push('/utility/airtime')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onAddBeneficiary,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addBeneficiary: () => addBeneficiary(instance)
    }
}

export default Service
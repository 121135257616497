import React from 'react'
import { Switch, Route } from "react-router-dom"
import Beneficiaries from './beneficiaries/beneficiary.controller'
import Order from './order/order.page'
import CreateOrder from './order/create/create.page'

const AirtimeController = () => {
    return (
        <div className="airtime">
            <Switch>
                <Route path="/utility/airtime/order/create" component={CreateOrder} />
                <Route path="/utility/airtime/order" component={Order} />
                <Route path="/utility/airtime" component={Beneficiaries} />
            </Switch>
        </div>
    )
}

export default AirtimeController
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './menu.scss'
import Item from './item/item.component'

class Menu extends React.Component {

    constructor(props) {
        super()
        this.state = { ...props.menu }
    }

    getActiveKey = () => {
        let pathname = this.props.location.pathname
        for(let i = 0; i < this.state.items.length; i++){
            if(i > 0 && pathname.includes(this.state.items[i].url)){
                return i
            }
            else if (i === 0 && pathname === "/"){
                return i
            }
        }
        return 0;
    }

    isActive = key => {
        return key === this.getActiveKey()
    }

    render() {
        return (
            <div className="menu">
                <div className="slider">
                    <strong style={{width:(this.getActiveKey() * 25) + "%"}}></strong><span><i></i></span>
                </div>
                <ul>
                    {
                        this.props.menu.items.map(
                            (item, key) => <Item key={key} active={this.isActive(key)} {...item} />
                        )
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.menu
    }
}

export default connect(mapStateToProps)(withRouter(Menu))
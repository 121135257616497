import React from 'react'
import { Switch, Route } from "react-router-dom"
import ElectricityController from './electricity/electricity.controller'
import AirtimeController from './airtime/airtime.controller'

const Controller = () => {
    return (
        <div className="utility">
            <Switch>
                <Route path="/utility/electricity" component={ElectricityController} />
                <Route path="/utility/airtime" component={AirtimeController} />
            </Switch>
        </div>
    )
}

export default Controller
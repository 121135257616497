import Requests from '../../../../utils/Requests'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        contactName: "",
        contactPhone: "",
        areaId: "",
        area: null,
        street: "",
        landmark: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.contactName, (error) => errors.contactName = error, { min: { value: 3, error: "Invalid" }, max: { value: 50, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.contactPhone, (error) => errors.contactPhone = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.street, (error) => errors.street = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.landmark, (error) => errors.landmark = error, { min: { value: 2, error: "Too short" }, max: { value: 30, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.areaId, (error) => errors.areaId = error, { min: { value: 10, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addAddress = instance => {

    let data = {
        contactName: instance.state.contactName.trim(),
        contactPhone: instance.state.contactPhone,
        location: instance.state.street.trim(),
        landmark: instance.state.landmark.trim(),
        areaId: instance.state.areaId,
        primary: false
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/delivery-address/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleAddAddressResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleAddAddressResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            sessionStorage.setItem("deliveryAddress", JSON.stringify(response.data.deliveryAddress))
            instance.props.history.push('/marketplace/checkout')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onAddAddress,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addAddress: () => addAddress(instance)
    }
}

export default Service
import React from 'react'
import { Switch, Route } from "react-router-dom"
import Deliverables from './deliverables.page'
import DeliverableItems from './deliverable-items/deliverable-items.page'
import Detail from './deliverable-items/detail/detail.page'

const DeliverablesController = () => {
    return (
        <div className="deliverables">
            <Switch>
            <Route path="/marketplace/:sku/details" component={Detail} />
                <Route path="/marketplace/:sku" component={DeliverableItems} />
                <Route path="/marketplace" component={Deliverables} />
            </Switch>
        </div>
    )
}

export default DeliverablesController
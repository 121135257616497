import React from 'react'
import FixedAmounts from './fixed-amounts/fixed-amounts.component'
import NetworkProvider from './network-provider/network-provider.component'
import Checkbox from '../../../../../components/inputs/checkbox/checkbox.component'
import DataBundle from './data-bundle/data-bundle.component'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                {
                    props.isNewBeneficiary &&
                    <>
                        <div className="row" id="network">
                            <label>Network Provider</label>
                            <NetworkProvider network={props.network} onChange={props.onNetworkChange} />
                            <span className="error">{props.errors.network}</span>
                        </div>
                    </>
                }
                {
                    props.orderType === 1 && <>
                        <div className="row" id="dataBundle">
                            <label>Select Data Bundle</label>
                            <DataBundle dataBundle={props.dataBundle} network={props.network} onChange={props.onDataBundleChange} />
                            <span className="error">{props.errors.dataBundle}</span>
                        </div>
                    </>
                }
                {
                    props.orderType === 0 && <FixedAmounts amount={props.amount} onChange={amount => props.onChange({
                        target: {
                            name: "amount",
                            value: amount
                        }
                    })} />
                }
                <div className="row" id="amount">
                    <label>Amount</label>
                    <div className="form-input">
                        <input type="text" name="amount" placeholder="Enter Amount" value={props.amount} disabled={props.orderType === 1} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
                {
                    props.isNewBeneficiary &&
                    <>
                        <div className="row" id="number">
                            <label>Phone Number</label>
                            <div className="form-input">
                                <input type="text" name="number" placeholder="Enter Phone Number" value={props.number} onChange={props.onChange} />
                                <span className="error">{props.errors.number}</span>
                            </div>
                        </div>
                        <Checkbox name="shouldSavedBeneficiary" text="Save Beneficiary" checked={props.shouldSavedBeneficiary} onChange={props.onShouldSaveBeneficiaryChange} />
                        {
                            props.shouldSavedBeneficiary && <>
                                <div className="row" id="name">
                                    <label>Name</label>
                                    <div className="form-input">
                                        <input type="text" name="name" placeholder="Enter Name" value={props.name} onChange={props.onChange} />
                                        <span className="error">{props.errors.name}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <button className="btn action" type="submit">Buy</button>
        </form>
    )
}

export default Form
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginForm from './form/form.page'
import FormHelper from '../../../utils/FormHelper'
import Service from './login.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class AuthLogin extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Auth | Login'
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.onLogin()
    }

    onLogin = () => {
        this.service.isValid() && this.setState({
            ...this.state,
            processing: true,
            showError: false
        }, () => this.service.login())
    }

    onRememberMeChange = () => this.setState({
        ...this.state,
        rememberMe: !this.state.rememberMe
    })

    render() {
        return (
            <div className="login">
                {this.state.processing && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <h3>Sign In</h3>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                <LoginForm onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} onRememberMeChange={this.onRememberMeChange} {...this.state} />
                <div className="new-account">
                    <p>Don't have an account?</p>
                    <Link className="signup" to='/auth/register'>Sign Up</Link>
                </div>
            </div>
        )
    }
}

export default connect(null)(withRouter(AuthLogin))
import React from "react"
import iconClose from '../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../components/dialog/dialog.component"
import './renew-cycle-chooser.styles.scss'
import RenewCycle from "./renew-cycle/renew-cycle.component"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

class RenewCycleChooser extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            renewCycles: [
                {
                    label: "1 Month",
                    value: 1
                },
                {
                    label: "2 Months",
                    value: 2
                },
                {
                    label: "3 Months + Free 1 week",
                    value: 3
                },
                {
                    label: "6 Months + Free 2 weeks",
                    value: 6
                },
                {
                    label: "9 Months + Free 3 weeks",
                    value: 9
                },
                {
                    label: "1 Year + Free 1 month",
                    value: 12
                }
            ]
        }
    }

    render(){
        return (
            <Dialog>
                <div className="renew-cycle-chooser">
                    <h3>
                        <span>Select Renew Cycle</span>
                        <img alt="close" src={iconClose} onClick={this.props.onClose} />
                    </h3>
                    <div className="renew-cycles">
                        {
                            this.state.renewCycles.map((renewCycle, key) => <RenewCycle key={key} {...renewCycle} onClick={() => this.props.onSelect(renewCycle)}/>)
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(RenewCycleChooser))
import React from 'react'
import AreaInput from './area-input/area-input.component'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="contactName">
                    <label>Name</label>
                    <div className="form-input">
                        <input type="text" name="contactName" placeholder="Receiver's Name" value={props.contactName} onChange={props.onChange} />
                        <span className="error">{props.errors.contactName}</span>
                    </div>
                </div>
                <div className="row" id="contactPhone">
                    <label>Phone</label>
                    <div className="form-input">
                        <input type="text" name="contactPhone" placeholder="Receiver's Phone" value={props.contactPhone} onChange={props.onChange} />
                        <span className="error">{props.errors.contactPhone}</span>
                    </div>
                </div>
                <div className="row" id="street">
                    <label>Street Name</label>
                    <div className="form-input">
                        <input type="text" name="street" placeholder="e.g 10, iriaye close" value={props.street} onChange={props.onChange} />
                        <span className="error">{props.errors.street}</span>
                    </div>
                </div>
                <div className="row" id="landmark">
                    <label>Landmark</label>
                    <div className="form-input">
                        <input type="text" name="landmark" placeholder="e.g close to Redeem Church" value={props.landmark} onChange={props.onChange} />
                        <span className="error">{props.errors.landmark}</span>
                    </div>
                </div>
                <div className="row" id="distributor">
                    <label>Area</label>
                    <AreaInput area={props.area} onChange={props.onAreaChange}/>
                    <span className="error">{props.errors.areaId}</span>
                </div>
            </div>
            <button className="btn action" type="submit">Save & Continue</button>
        </form>
    )
}

export default Form
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './accounts.styles.scss'
import Account from './account/account'
import Service from './accounts.service'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import '../common.styles.scss'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import SubHeader from '../../../../components/sub-header/sub-header.component'
import EmptyList from '../../../../components/empty-list/empty-list.component'
import DeleteAccount from './components/delete/delete.component'
import iconBack from '../../../../assets/icons/arrow-back.svg'
import iconInfo from '../../../../assets/icons/info.svg'

class Accounts extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Internet | User Accounts';
        this.onLoadAccounts()
    }

    onLoadAccounts = () => {
        this.setState({
            ...this.state,
            loadingAccounts: true,
            showError: false
        }, () => this.service.loadAccounts())
    }

    onAccountSelected = account => {
        sessionStorage.setItem("currentAccount", JSON.stringify(account))
        this.props.history.push('/internet/skysenx/order')
    }

    onDeleteAccount = account => {
        this.setState({
            ...this.state,
            showDeleteAccount: true,
            accountToDelete: account
        })
    }

    onAccountDeleted = () => {
        let accounts = this.state.accounts.filter(account => account.id !== this.state.accountToDelete.id)
        this.setState({
            ...this.state,
            accounts: accounts,
            showDeleteAccount: false,
            accountToDelete: null
        })
    }

    onCancelDeleteAccount = () => {
        this.setState({
            ...this.state,
            showDeleteAccount: false,
            accountToDelete: null
        })
    }

    render() {
        return (
            <div className="accounts">
                {this.state.loadingAccounts && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                {this.state.showDeleteAccount && <Dialog><DeleteAccount account={this.state.accountToDelete} onDeleted={this.onAccountDeleted} onCancel={this.onCancelDeleteAccount} /></Dialog>}
                <header className="app">
                    <Link className="back" to={"/dashboard"}><img alt="back" src={iconBack} /></Link>
                    <p>Skysenx Internet</p>
                    <Link className="info" to="/internet/skysenx/about"><img alt="info" src={iconInfo} /></Link>
                </header>
                <SubHeader title="User Account" url="/internet/skysenx/account/create" />
                <div className="records">
                    {
                        this.state.accounts.map((account, key) => <Account onClick={() => this.onAccountSelected(account)} onDeleteClick={() => this.onDeleteAccount(account)} index={key} key={key} {...account} />)
                    }
                </div>
                {
                    !this.state.loadingAccounts && this.state.accounts.length === 0 && <EmptyList title="oops! no saved user account" />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        internet: state.internet,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Accounts))
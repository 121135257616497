import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PasswordInput from '../../../../components/inputs/password/password.component'

const LoginForm = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Email</label>
                <input type="email" name="username" value={props.username} placeholder="Username (Email)" onChange={props.onChange} />
                <span className="error">{props.errors.username}</span>
            </div>
            <div className="form-input">
                <label>Password</label>
                <PasswordInput onChange={props.onChange} placeholder="Password" />
                <span className="error">{props.errors.password}</span>
            </div>
            <div className="reset">
                <Link to='/auth/forgot'>Forgot Password?</Link>
            </div>
            <div className="actions">
                <button className={props.processing ? 'btn action disabled' : 'btn action'}>Sign In</button>
            </div>
        </form>
    )
}

export default withRouter(LoginForm)
import React from 'react'
import './order.styles.scss'
import NumberFormat from 'react-number-format'
import imageArrow from '../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'
import StatusLabels from '../../data/status-label'
import Logo from '../../components/brands/logo/logo.component'

const Order = props => {
    return (
        <div className="order" onClick={() => props.history.push('/order/' + props.number)}>
            {getImage(props)}
            <div className="content">
                <div>
                    <p>#{props.number}</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div>
                    <NumberFormat value={props.totalAmount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <strong>{value}</strong>} />
                    <strong className="status">{StatusLabels[props.status]}</strong>
                </div>
            </div>
            <img alt="logo" src={imageArrow} />
        </div>
    )
}

const getImage = props => {
    switch (props.type) {
        case 1:
            return getCabletvProviderLogo(props.cabletv.decoder.type)
        case 2:
            return getElectricityDistributorLogo(props.electricity.meter.distributor)
        case 3:
            return <Logo name="shawarma" />
        case 4:
            return getAirtimeProviderLogo(props.airtime.network)
        case 5:
            return <Logo name="ticket" />
        case 0:
        default:
            return <Logo name="skysenx-internet" />
    }
}

const getCabletvProviderLogo = type => {
    switch (type) {
        case 1:
            return <Logo name="gotv" />
        case 2:
            return <Logo name="startimes" />
        default:
            return <Logo name="dstv" />
    }
}

const getAirtimeProviderLogo = network => {
    switch (network) {
        case 1:
            return <Logo name="airtel" />
        case 2:
            return <Logo name="glo" />
        case 3:
            return <Logo name="9mobile" />
        default:
            return <Logo name="mtn" />
    }
}

const getElectricityDistributorLogo = distributor => {
    switch (distributor) {
        case 0:
            return <Logo name="bedc" />
        case 1:
            return <Logo name="ikedc" />
        case 2:
            return <Logo name="ekedc" />
        case 3:
            return <Logo name="phed" />
        case 4:
            return <Logo name="aedc" />
        case 5:
            return <Logo name="kedco" />
        case 6:
            return <Logo name="jed" />
        case 7:
            return <Logo name="ibedc" />
        case 8:
            return <Logo name="kaedco" />
        case 9:
            return <Logo name="eedc" />
        default:
            return <Logo name="bedc" />
    }
}

export default withRouter(Order)
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingAddresses: false,
        addresses: [],
        showError: false,
        showDeleteMeter: false,
        meterToDelete: null,
        alertError: {}
    }
}

const loadAddresses = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/delivery-addresses")
        .withAuthorization(instance.props.auth.authorization)
        .withData({maxSize: 20, offset: 0})
        .then(response => {
            handleLoadAddressesResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadAddressesResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingAddresses: false,
                addresses: response.data.deliveryAddresses
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingAddresses: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadAddresses,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const AddressesWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadAddresses: () => loadAddresses(instance)
    }
}

export default AddressesWorker
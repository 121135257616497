import Requests from "../../../../../utils/Requests"
import Cart from '../../../cart.service'

const getDefaultState = () => {
    return {
        loadingDeliveryOptions: true,
        deliveryOptions: [],
        itemsWeight: Cart().itemsWeight(),
        showError: false,
        alertError: {}
    }
}

const loadDeliveryOptions = instance => {

    let data = {
        areaId: instance.props.areaId,
        itemsWeight: instance.props.itemsWeight,
        offset: 0,
        maxSize: 20
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/delivery/delivery-options")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadDeliveryOptionsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliveryOptionsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDeliveryOptions: false,
                deliveryOptions: response.data.deliveryOptions
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDeliveryOptions,
            onClose: () => instance.props.history.push('/marketplace')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDeliveryOptions: () => loadDeliveryOptions(instance)
    }
}

export default Service
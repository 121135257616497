import React from 'react'
import './required.styles.scss'
import Header from '../../../components/header/header.component'
import Device from '../../../utils/Device'

const Required = () => {
    return (
        <>
            <Header title="Update Required" backUrl="/dashboard"/>
            <div className="required">
                {Device.getInfo().type === Device.types.ios? <p>Update on Appstore</p>: <p>Update on Playstore</p>}
            </div>
        </>
    )
}

export default Required
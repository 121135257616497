import React from "react"
import iconSkysenxInternet from '../../../../../assets/icons/internet-skysenx.svg'
import './account-info.styles.scss'

const AccountInfo = props => {
    return (
        <div className="account-info">
            <div>
                <img alt="icon" src={iconSkysenxInternet} />
            </div>
            <div>
                <h4>{props.userId}</h4>
                <p>
                    {props.name}
                </p>
            </div>
        </div>
    )
}

export default AccountInfo
import React from 'react'

const LoginForm = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="username">
                    <label>Internet User ID</label>
                    <div className="form-input">
                        <input type="username" name="username" placeholder="Enter User ID" value={props.username} onChange={props.onChange} />
                        <span className="error">{props.errors.username}</span>
                    </div>
                </div>
                <div className="row" id="password">
                    <label>Internet Password</label>
                    <div className="form-input">
                        <input type="password" name="password" placeholder="Enter Password" value={props.password} onChange={props.onChange} />
                        <span className="error">{props.errors.password}</span>
                    </div>
                </div>
            </div>
            <button className="btn action" type="submit">Save & Continue</button>
        </form>
    )
}

export default LoginForm
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './home.styles.scss'
import imageLogo from '../../assets/icons/logo-only.svg'
import Wallet from './components/wallet/wallet.component'
import iconDstv from '../../assets/icons/home-dstv.svg'
import iconGotv from '../../assets/icons/home-gotv.svg'
import iconStartimes from '../../assets/icons/home-startimes.svg'
import iconMtn from '../../assets/icons/mtn.svg'
import iconAirtel from '../../assets/icons/home-airtel.svg'
import iconGlo from '../../assets/icons/glo.svg'
import icon9Mobile from '../../assets/icons/home-9mobile.svg'
import iconBedc from '../../assets/icons/home-bedc.svg'
import iconSkysenxInternet from '../../assets/icons/home-skysenx-internet.svg'
import iconAedc from '../../assets/icons/aedc-transparent.svg'
import iconEkedc from '../../assets/icons/ekedc-transparent.svg'
import iconIbedc from '../../assets/icons/ibedc-transparent.svg'
import iconIkedc from '../../assets/icons/ikedc-transparent.svg'
import iconJed from '../../assets/icons/jed-transparent.svg'
import iconKaedco from '../../assets/icons/kaedco-transparent.svg'
import iconKedco from '../../assets/icons/kedco-transparent.svg'
import iconPhed from '../../assets/icons/phed-transparent.svg'
import iconTicketing from '../../assets/icons/home-ticketing.svg'
import iconEedc from '../../assets/icons/home-eedc.svg'
import { connect } from 'react-redux'
import Slides from './components/slides/slides.component'

class HomePage extends React.Component {

    componentDidMount() {
        document.title = 'Home'
    }

    getGreeting = () => {
        const hourOfDay = new Date().getHours()
        if (hourOfDay >= 17) {
            return "Good evening"
        }
        else if (hourOfDay >= 12) {
            return "Good afternoon"
        }
        else {
            return "Good morning"
        }
    }

    render() {
        return (
            <div className="home">
                <header>
                    {
                        this.props.auth.customer ? <p>{this.getGreeting()}, {this.props.auth.customer.firstname}</p> : <p>{this.getGreeting()}</p>
                    }
                    <img alt="logo" src={imageLogo} />
                </header>
                <section>
                    {
                        this.props.auth.authorization && <Wallet />
                    }
                    <div className="services">

                        <div className="row x4">
                            <div className="service" onClick={() => this.props.history.push('/utility/airtime')}>
                                <img alt="mtn" src={iconMtn} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/airtime')}>
                                <img alt="airtel" src={iconAirtel} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/airtime')}>
                                <img alt="glo" src={iconGlo} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/airtime')}>
                                <img alt="9mobile" src={icon9Mobile} />
                            </div>
                        </div>

                        <div className="row x4">
                            <div className="service" onClick={() => this.props.history.push('/cabletv/decoder')}>
                                <img alt="DSTV" src={iconDstv} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/cabletv/decoder')}>
                                <img alt="Gotv" src={iconGotv} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/cabletv/decoder')}>
                                <img alt="Startimes" src={iconStartimes} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/internet/skysenx')}>
                                <img alt="BEDC" src={iconSkysenxInternet} />
                            </div>
                        </div>

                        <Slides/>

                        <div className="row x2">
                            <Link className="service" to="/ticketing">
                                <img alt="ticketing" src={iconTicketing} />
                            </Link>
                            <Link className="service" to="/utility/electricity">
                                <img alt="bedc" src={iconBedc} />
                            </Link>
                        </div>

                        <div className="row x3">
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="AEDC" src={iconAedc} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="EEDC" src={iconEedc} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="EKEDC" src={iconEkedc} />
                            </div>
                        </div>

                        <div className="row x3">
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="IBEDC" src={iconIbedc} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="IKEDC" src={iconIkedc} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="JED" src={iconJed} />
                            </div>
                        </div>

                        <div className="row x3">
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="KAEDCO" src={iconKaedco} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="KEDCO" src={iconKedco} />
                            </div>
                            <div className="service" onClick={() => this.props.history.push('/utility/electricity')}>
                                <img alt="PHED" src={iconPhed} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(HomePage))

import React from 'react'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Header from '../../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import { connect } from 'react-redux'
import AccountInfo from './account-info/account-info.component'
import AccountPlan from './account-plan/account-plan.component'
import Plan from './plan/plan.component'
import RenewCycle from './renew-cycle/renew-cycle.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Internet | Prepare Order';
    }

    onPlanChange = plan => {
        let renewCycle = plan.validity === 30
            ? this.state.renewCycle
            : {
                label: "1 Month",
                value: 1
            }
        this.setState({
            ...this.state,
            plan: plan,
            renewCycle: renewCycle
        })
    }

    onRenewCycleChange = renewCycle => {
        this.setState({
            ...this.state,
            renewCycle: renewCycle
        })
    }

    onPay = () => {
        if (true) {
            let internetOrder = {
                account: this.state.account,
                plan: this.state.plan,
                renewCycle: this.state.renewCycle
            }

            sessionStorage.setItem("internetOrder", JSON.stringify(internetOrder))
            this.props.history.push('/internet/skysenx/order/create')
        }
    }

    render() {
        if (this.state.account === null) {
            return <></>
        }
        return (
            <>
                <Header title="Skysenx Internet" backUrl="/internet/skysenx" />
                <div className="order">
                    {this.state.account !== null && <div className="summary">
                        <AccountInfo {...this.state.account} />
                        {
                            this.state.account.plan !== null && <AccountPlan account={this.state.account} />
                        }
                    </div>}
                    <br />

                    {
                        this.state.account.type === 1 &&
                        <div className="form-control">
                            <label>Select to change Plan</label>
                            <Plan plan={this.state.plan} account={this.state.account} onChange={this.onPlanChange} />
                        </div>
                    }

                    {
                        (this.state.plan !== null && this.state.plan.validity === 30) && <>
                            <div className="form-control">
                                <label>Renew Cycle</label>
                                <RenewCycle renewCycle={this.state.renewCycle} onChange={this.onRenewCycleChange} />
                            </div>
                        </>
                    }

                    {
                        this.state.plan !== null && <NumberFormat value={(this.state.plan.amount * this.state.renewCycle.value) / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <button onClick={this.onPay} className="btn action">Pay - {value}</button>} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        internet: state.internet
    }
}

export default connect(mapStateToProps)(withRouter(Order))
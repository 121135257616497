import React from 'react'
import Header from '../../../components/header/header.component'
import Service from './change-password.service'
import Form from './form/form.page'
import FormHelper from '../../../utils/FormHelper'
import './change-password.styles.scss'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class ChangePassword extends React.Component {

    constructor() {
        super()
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Settings | Change Password'
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onUpdatePassword()
    }

    onUpdatePassword = () => this.setState({
        ...this.state,
        showError: false,
        updating: true
    }, () => this.service.updatePassword())

    render() {
        return (
            <>
                <Header title="Change Password" backUrl="/settings" />
                <div className="change-password">
                    {this.state.updating && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    <br />
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} {...this.state} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(ChangePassword))
import React from "react"
import iconClose from '../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../components/dialog/dialog.component"
import './plan-chooser.styles.scss'
import Service from "./plan-chooser.service"
import Plan from "./plan/plan.component"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Loading from '../../../../../../components/loading/loading.component'

class PlanChooser extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadPlans()
    }

    getPlans = () => {
        return this.state.plans.filter(plan => (this.state.tab === 0 ? plan.validity === 30 : plan.validity < 30))
    }

    onTabSelected = index => {
        this.setState({
            ...this.state,
            tab: index
        })
    }

    render() {
        return (
            <Dialog>
                <div className="plan-chooser">
                    {this.state.loadingPlans && <Dialog><Loading /></Dialog>}
                    <h3>
                        <span>Select Internet Plan</span>
                        <img alt="close" src={iconClose} onClick={this.props.onClose} />
                    </h3>
                    <div className="plan-types">
                        <span className={this.state.tab === 0 ? 'active' : ''} onClick={() => this.onTabSelected(0)}>Monthly</span>
                        <span className={this.state.tab === 1 ? 'active' : ''} onClick={() => this.onTabSelected(1)}>Weekly</span>
                    </div>
                    <div className={this.state.tab === 0 ? 'plans monthly' : 'plans weekly'}>
                        {
                            this.getPlans().map((plan, key) => <Plan key={key} {...plan} onClick={() => this.props.onSelect(plan)} isCurrentPlan={this.props.account.plan !== null && this.props.account.plan.id === plan.id} />)
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(PlanChooser))
import React from 'react'
import NumberFormat from 'react-number-format'
import QuantityModifier from '../../components/quantity-modifier/quantity-modifier.component'
import './item.styles.scss'

const Item = props => {
    return (
        <div className="item">
            <img alt="logo" src={props.imageUrl} />
            <div>
                <h4>{props.name}</h4>
                <p>{props.description}</p>
                <div className="controls">
                    <QuantityModifier value={props.quantity} onDecrease={props.onDecrease} onIncrease={props.onIncrease}/>
                    <span onClick={props.onDelete}>Delete</span>
                </div>
            </div>
            <NumberFormat value={(props.amount * props.quantity) / 100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>N{value}</strong>} />
        </div>
    )
}

export default Item
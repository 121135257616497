import Requests from '../../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingMeters: false,
        meters: [],
        showError: false,
        searchKeywords: "",
        showDeleteMeter: false,
        meterToDelete: null,
        alertError: {}
    }
}

const loadMeters = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/electricity/meters")
        .withAuthorization(instance.props.auth.authorization)
        .withData({maxSize: 500, offset: 0})
        .then(response => {
            handleLoadMetersResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadMetersResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingMeters: false,
                meters: response.data.meters
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingMeters: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadMeters,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const MetersWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadMeters: () => loadMeters(instance)
    }
}

export default MetersWorker
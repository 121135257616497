import React from 'react'
import { withRouter } from 'react-router-dom'
import './addresses.styles.scss'
import Header from '../../../components/header/header.component'
import Address from './address/address.component'
import Service from './addresses.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import SubHeader from '../../../components/sub-header/sub-header.component'
import DeleteAddress from './components/delete/delete.component'
import EmptyList from '../../../components/empty-list/empty-list.component'

class Addresses extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Marketplace | Saved Addresses'
        this.onLoadAddresses()
    }

    onLoadAddresses = () => {
        this.setState({
            ...this.state,
            loadingAddresses: true,
            showError: false
        }, () => this.service.loadAddresses())
    }

    onAddressSelected = address => {
        sessionStorage.setItem("deliveryAddress", JSON.stringify(address))
        this.props.history.push('/marketplace/checkout')
    }

    onDeleteAddress = address => {
        this.setState({
            ...this.state,
            showDeleteAddress: true,
            addressToDelete: address
        })
    }

    onAddressDeleted = () => {
        let addresses = this.state.addresses.filter(address => address.id !== this.state.addressToDelete.id)
        this.setState({
            ...this.state,
            addresses: addresses,
            showDeleteAddress: false,
            addressToDelete: null
        })
    }

    onCancelDeleteAddress = () => {
        this.setState({
            ...this.state,
            showDeleteAddress: false,
            addressToDelete: null
        })
    }

    render() {
        return (
            <>
                <Header title="Location" backUrl="/marketplace/cart" cartBox={true}/>
                <SubHeader title="Addresses" url="/marketplace/addresses/create" />
                <div className="addresses">
                    {this.state.loadingAddresses && <Dialog><Loading></Loading></Dialog>}
                    {this.state.showDeleteAddress && <Dialog><DeleteAddress address={this.state.addressToDelete} onDeleted={this.onAddressDeleted} onCancel={this.onCancelDeleteAddress} /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.error} />}
                    <label>Choose delivery address</label>
                    <div className="records">
                        {
                            this.state.addresses.map((address, key) => <Address onClick={() => this.onAddressSelected(address)} onDeleteClick={() => this.onDeleteAddress(address)} key={key} {...address} street={address.location} />)
                        }
                    </div>
                    {
                        !this.state.loadingAddresses && this.state.addresses.length === 0 && <EmptyList title="oops! no saved beneficiary" />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cabletv: state.cabletv,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Addresses))
import React from 'react'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Header from '../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import TicketType from './ticket-type/ticket-type.component'
import EventInfo from './event-info/event-info.component'
import Quantity from './quantity/quantity.component'
import Tags from './tags/tags.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Ticketing | Prepare Order';
        this.service.loadEvent()
    }

    toggleShowCreateOrder = () => {
        this.setState({
            ...this.state,
            showCreateOrder: !this.state.showCreateOrder
        })
    }

    onTicketTypeChange = ticketType => {

        let quantity = {
            label: "1 of " + ticketType.name,
            value: 1
        }

        this.setState({
            ...this.state,
            ticketType: ticketType,
            quantity: quantity,
            tags: this.generateTags(this.getQuantities(ticketType), quantity)
        })
    }

    onQuantityChange = quantity => {
        this.setState({
            ...this.state,
            quantity: quantity,
            tags: this.generateTags(this.getQuantities(this.state.ticketType), quantity)
        })
    }

    generateTags = (quantities, quantity) => {
        let tags = []

        for (let i = 1; i <= quantity.value; i++) {
            tags.push(
                {
                    name: quantities[i - 1].value,
                    label: quantities[i - 1].label,
                    value: ""
                }
            )
        }

        return tags
    }

    getQuantities = ticketType => {
        let quantities = []

        for (let i = 1; i <= ticketType.maxSeatsPerOrder; i++) {
            quantities.push({
                label: i + " of " + ticketType.name + " Ticket",
                value: i
            })
        }

        return quantities
    }

    onTagChanged = e => {
        let tags = this.state.tags
        tags[Number(e.target.name)-1].value = e.target.value
        this.setState({
            ...this.state,
            tags: tags
        })
    }

    onPay = () => {
        let ticketOrder = {
            event: this.state.event,
            ticketType: this.state.ticketType,
            quantity: this.state.quantity,
            tags: this.state.tags
        }

        sessionStorage.setItem("ticketOrder", JSON.stringify(ticketOrder))
        this.props.history.push('/ticketing/order/create')
    }

    render() {
        if (this.state.loadingEvent === null) {
            return <Dialog><Loading /></Dialog>
        }
        return (
            <>
                <Header title="Ticketing" backUrl="/ticketing" />
                <div className="order">
                    {this.state.loadingEvent && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    {this.state.event !== null && <div className="summary"><EventInfo {...this.state.event} /></div>}
                    <br />
                    <div className="form-control">
                        <label>Select to change Ticket Type</label>
                        <TicketType ticketType={this.state.ticketType} event={this.state.event} onChange={this.onTicketTypeChange} />
                    </div>

                    <div className="form-control">
                        <label>Amount</label>
                        <div className="amount">
                            <NumberFormat value={this.state.ticketType.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
                        </div>
                    </div>

                    {
                        (this.state.ticketType !== null) && <>
                            <div className="form-control">
                                <label>Quantity</label>
                                <Quantity quantity={this.state.quantity} quantities={this.getQuantities(this.state.ticketType)} onChange={this.onQuantityChange} />
                            </div>
                        </>
                    }

                    {
                        this.state.ticketType.amount > 0 && <>
                            <div className="form-control">
                                <label><b>Tag Ticket(s)</b></label>
                                <Tags tags={this.state.tags} onChange={this.onTagChanged} />
                            </div>
                        </>
                    }

                    {
                        this.state.ticketType.amount > 0 && <NumberFormat value={(this.state.ticketType.amount * this.state.quantity.value) / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <button onClick={this.onPay} className="btn action">Pay - {value}</button>} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Order))
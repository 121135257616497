import React from 'react'
import Header from '../../../../components/header/header.component'
import './about.styles.scss'

const About = () => {
    document.title = 'Internet | About';
    return (
        <>
            <Header title="Skysenx Internet" backUrl="/internet/skysenx" />
            <div className="about">
                <h4>About Us</h4>
                <p>
                    Skysenx Wireless is an NCC Licensed Internet Service Provider (ISP)
                    known for quality and reliability, delivering unlimited data with
                    dedicated bandwidth for today's demand for browsing and streaming.
                </p>
                <br/>
                <h4>Coverage Area</h4>
                <p>
                    Warri - Effurun - Ekpan - Jeddo  - Osubi
                </p>
                <p>
                    Otokutu - Udu - Opete - Okpaka - Asaba
                </p>
            </div>
        </>
    )
}

export default About
import React from 'react'
import {useEffect} from 'react'
import { useLocation, BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './app.styles.scss'
import InternetController from './pages/internet/internet.controller'
import OrderController from './pages/order/order.controller'
import SettingsController from './pages/setting/setting.controller'
import WalletController from './pages/wallet/wallet.controller'
import Home from './pages/home/home.page'
import DefaultTemplate from './templates/default/default.template'
import AuthTemplate from './templates/auth/auth.template'
import AuthController from './pages/auth/auth.controller'
import PagesTemplate from './templates/pages/pages.template'
import StaticContentController from './pages/static/static.controller'
import RequireLogin from './components/require-login/require-login.component'
import Splash from './pages/splash/splash.page'
import Welcome from './pages/welcome/welcome.page'
import UpdateController from './pages/update/update.controller'
import CabletvController from './pages/cabletv/cabletv.controller'
import UtilityController from './pages/utility/utility.controller'
import MarketplaceController from './pages/marketplace/marketplace.controller'
import TicketingController from './pages/ticketing/ticketing.controller'

const AppController = () => {
    return (
        <Router>
            <Switch>
                <Route path="/splash" component={() => <Splash />} />
                <Route path="/welcome" component={() => <Welcome />} />
                <Route path="/auth" component={() => <AuthTemplate><AuthController /></AuthTemplate>} />
                <Route path="/pages" component={() => <PagesTemplate><StaticContentController /></PagesTemplate>} />
                <Route path="/settings" component={() => <RequireLogin><DefaultTemplate><SettingsController /></DefaultTemplate></RequireLogin>} />
                <Route path="/wallet" component={() => <RequireLogin><DefaultTemplate><WalletController /></DefaultTemplate></RequireLogin>} />
                <Route path="/order" component={() => <RequireLogin><DefaultTemplate><OrderController /></DefaultTemplate></RequireLogin>} />
                <Route path="/internet/skysenx" component={() => <RequireLogin><DefaultTemplate><InternetController /></DefaultTemplate></RequireLogin>} />
                <Route path="/cabletv" component={() => <RequireLogin><DefaultTemplate><CabletvController /></DefaultTemplate></RequireLogin>} />
                <Route path="/utility" component={() => <RequireLogin><DefaultTemplate><UtilityController /></DefaultTemplate></RequireLogin>} />
                <Route path="/marketplace" component={() => <RequireLogin><DefaultTemplate><MarketplaceController /></DefaultTemplate></RequireLogin>} />
                <Route path="/ticketing" component={() => <RequireLogin><DefaultTemplate><TicketingController /></DefaultTemplate></RequireLogin>} />
                <Route path="/update" component={() => <RequireLogin><DefaultTemplate><UpdateController /></DefaultTemplate></RequireLogin>} />
                <Route path="/dashboard" component={() => <DefaultTemplate><Home /></DefaultTemplate>} />
                <Route exact path="/" component={() => <Splash />} />
            </Switch>
        </Router>
    )
}

export const usePageTracking = () => {
    const location = useLocation();
  
    useEffect(() => {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }, [location]);
  };

export default AppController
import React from 'react'
import './delete.styles.scss'
import { withRouter } from 'react-router-dom'
import Service from './delete.service'
import { connect } from 'react-redux'
import Loading from '../../../../../../components/loading/loading.component'
import '../../../common.styles.scss'
import AlertDialog from '../../../../../../components/dialog/alert/alert.component'
import Confirmation from '../../../../../../components/confirmation/confirmation.component'

class Delete extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onDeleteAccount = () => {
        this.setState({
            ...this.state,
            deleting: true
        }, this.service.deleteAccount)
    }

    render() {
        return (
            <div className="delete-account">
                {this.state.deleting && <Loading></Loading>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                <Confirmation title="Confirm Delete" onClose={this.props.onCancel} onNo={this.props.onCancel} onYes={this.onDeleteAccount}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Delete))
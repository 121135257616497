import Requests from "../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingBouquets: true,
        bouquets: [],
        showError: false,
        alertError: {}
    }
}

const loadBouquets = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/cabletv/bouquets")
        .withAuthorization(instance.props.auth.authorization)
        .withData({type: instance.props.decoder.type})
        .then(response => {
            handleLoadBouquetsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadBouquetsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingBouquets: false,
                bouquets: response.data.bouquets
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}
const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadBouquets,
            onClose: () => instance.props.history.push('/cabletv')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadBouquets: () => loadBouquets(instance)
    }
}

export default Service
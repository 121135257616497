import React from 'react'
import './sub-header.styles.scss'
import { withRouter } from 'react-router-dom'

const SubHeader = props => {
    return (
        <section className="sub-header">
            <p>{props.title}</p>
            <button onClick={() => onClick(props)}>
                <span>New</span>
                <span>+</span>
            </button>
        </section>
    )
}

const onClick = props => {
    if(props.url === "/utility/airtime/order"){
        sessionStorage.setItem("currentBeneficiary", null)
    }
    props.history.push(props.url)
}

export default withRouter(SubHeader)
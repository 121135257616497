import React from 'react'
import NumberFormat from 'react-number-format'
import Logo from '../../components/brands/logo/logo.component'
import Item from './item/item.component'
import StatusLabels from '../../data/status-label'
import './marketplace.styles.scss'

const Marketplace = props => {
    return (
        <div className="marketplace">
            <div className="top">
                <Logo name="shawarma" />
                <NumberFormat value={props.totalAmount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
                <div className="info">
                    <p>Delivery</p>
                    <p>{props.delivery.deliveryOption !== null? props.delivery.deliveryOption.name : "Standard"}, <NumberFormat value={props.delivery.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span><strong>{value}</strong></span>} /></p>
                </div>
                <div className="info">
                    <p>Delivery Contact</p>
                    <p>{props.delivery.contactName}</p>
                </div>
                <div className="info">
                    <p>Delivery Phone</p>
                    <p>{props.delivery.contactPhone}</p>
                </div>
                <div className="info">
                    <p>Delivery Address</p>
                    <p>{props.delivery.location}</p>
                </div>
                <div className="info">
                    <p></p>
                    <p>{props.delivery.landmark}</p>
                </div>
                <div className="info">
                    <p></p>
                    <p>{props.delivery.area}, {props.delivery.route}</p>
                </div>
            </div>
            <table className="items">
                <tbody>
                    {
                        props.orderLines.map((orderLine, key) => <Item key={key} {...orderLine} />)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Marketplace
import React from 'react'
import iconEmpty from '../../assets/icons/empty.svg'
import './empty-list.styles.scss'

const EmptyList = props => {
    return(
        <div className="empty-list">
            <img alt="empty" src={iconEmpty}/>
            <p>{props.title}</p>
        </div>
    )
}

export default EmptyList
import { AirtimeSetBeneficiaries } from '../../../../redux/reducers/airtime/actions'
import Requests from '../../../../utils/Requests'

const getDefaultState = () => {
    return {
        loadingBeneficiaries: false,
        beneficiaries: [],
        showError: false,
        alertError: {}
    }
}

const loadBeneficiaries = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/airtime/beneficiaries")
        .withAuthorization(instance.props.auth.authorization)
        .withData({maxSize: 20, offset: 0})
        .then(response => {
            handleLoadBeneficiariesResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadBeneficiariesResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingBeneficiaries: false,
                beneficiaries: response.data.beneficiaries
            })
            instance.props.dispatch(AirtimeSetBeneficiaries(response.data.beneficiaries))
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingBeneficiaries: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadBeneficiaries,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const BeneficiariesWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadBeneficiaries: () => loadBeneficiaries(instance)
    }
}

export default BeneficiariesWorker
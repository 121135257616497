import Requests from '../../../../../utils/Requests'
import Validation from '../../../../../data/validation/validation'
import { StringValidationRule } from '../../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        alias: "",
        name: "",
        number: "",
        type: "",
        distributor: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.distributor, (error) => errors.distributor = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.type, (error) => errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.alias, (error) => errors.alias = error, { min: { value: 3, error: "Too short" }, max: { value: 12, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.number, (error) => errors.number = error, { min: { value: 10, error: "Too short" }, max: { value: 13, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failed"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addMeter = instance => {

    let data = {
        type: Number(instance.state.type),
        name: instance.state.name,
        alias: instance.state.alias,
        distributor: instance.state.distributor,
        number: instance.state.number.trim()
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/electricity/meter/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleAddMeterResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleAddMeterResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            sessionStorage.setItem("currentMeter", JSON.stringify(response.data.meter))
            instance.props.history.push('/utility/electricity/order')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onAddMeter,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addMeter: () => addMeter(instance)
    }
}

export default Service
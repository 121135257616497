import React from 'react'
import './ticket-type.styles.scss'
import { withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../assets/icons/arrow-bottom-light.svg'
import TicketTypeChooser from './ticket-type-chooser/ticket-type-chooser.component'

class TicketType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showTicketTypeChooser: false
        }
    }

    toggleShowTicketTypeChooser = () => {
        this.setState({
            ...this.state,
            showTicketTypeChooser: !this.state.showTicketTypeChooser
        })
    }

    onSelect = ticketType => {
        this.props.onChange(ticketType)
        this.toggleShowTicketTypeChooser()
    }

    render() {
        return (
            <>
                {this.state.showTicketTypeChooser && <TicketTypeChooser ticketTypes={this.props.event.ticketTypes} onSelect={this.onSelect} onClose={this.toggleShowTicketTypeChooser} />}
                <div className="ticket-type" onClick={this.toggleShowTicketTypeChooser}>
                    <h4>{this.props.ticketType.name}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(TicketType)
import { createStore } from 'redux'
import Reducer from '../reducers/reducer'
import DefaultMenuStore from './defaults/menu'
import DefaultAuthStore from './defaults/auth'
import DefaultInternetStore from './defaults/internet'
import DefaultCableTVStore from './defaults/cabletv'
import DefaultElectricityStore from './defaults/electricity'
import DefaultAirtimeStore from './defaults/airtime'

const store = createStore(Reducer, {
    menu: DefaultMenuStore,
    auth: DefaultAuthStore,
    internet: DefaultInternetStore,
    cabletv: DefaultCableTVStore,
    electricity: DefaultElectricityStore,
    airtime: DefaultAirtimeStore
})

export default store
import React from "react"
import NumberFormat from "react-number-format"
import './account-plan.styles.scss'

const AccountPlan = props => {
    return (
        <div className="account-plan">
            <div>
                <p>Current Plan</p>
                <p><strong>{props.account.plan.name}</strong></p>
            </div>
            <div>
                <p>Amount</p>
                <p><NumberFormat value={props.account.plan.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <strong>{value}</strong>} /></p>
            </div>
            <div>
                <p>Expiry</p>
                <p><strong>{new Date(props.account.expiration * 1000).toString("dd MMM yyyy HH:mm")} {Number(new Date(props.account.expiration * 1000).toString("H")) < 12 ? "am" : "pm"}</strong></p>
            </div>
        </div>
    )
}

export default AccountPlan
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        verifying: true,
        tab: 0,
        showError: false,
        alertError: {}
    }
}

const verify = (instance) => {
    let data = {
        token: instance.props.match.params.token
    }
    Requests(instance.props)
        .post()
        .withUrl("/auth/customer/verify")
        .withData(data)
        .then(response => {
            handleCreateResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                tab: 1,
                verifying: false
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        verifying: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onVerify,
            onClose: () => instance.props.history.push('/auth/login')
        }
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        verify: () => verify(instance)
    }
}

export default Worker
import React from "react"
import './decoder-bouquet.styles.scss'

const DecoderBouquet = props => {
    return (
        <div className="decoder-bouquet">
            <div>
                <p>Name</p>
                <p><strong>{props.decoder.name}</strong></p>
            </div>
            <div>
                <p>Current Bouquet</p>
                <p><strong>{props.decoder.bouquetInfo.Current_Bouquet}</strong></p>
            </div>
            <div>
                <p>Due Date</p>
                <p><strong>{props.decoder.bouquetInfo.Due_Date}</strong></p>
            </div>
        </div>
    )
}

export default DecoderBouquet
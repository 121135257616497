import { DeviceType } from "../config"

const getDeviceName = () => {
    switch (DeviceType) {
        case 0:
            return window.Android.getDeviceName()
        case 1:
            return "iPhone"
        default:
            return window.navigator.userAgent
    }
}

const getFMSToken = () => {
    switch (DeviceType) {
        case 0:
            return window.Android.getFMSToken()
        default:
            return ""
    }
}

const getReferralUrl = () => {
    switch (DeviceType) {
        case 0:
            return window.Android.getReferralUrl()
        case 2:
            saveReferralUrl()
            return sessionStorage.getItem("referralUrl") ?? "";
        default:
            return ""
    }
}

const saveReferralUrl = () => {
    if (DeviceType === 2) {
        const urlParams = new URLSearchParams(window.location.search)
        const referralUrl = urlParams.get('referrer')
        if(referralUrl !== null){
            sessionStorage.setItem("referralUrl", referralUrl)
        }
    }
}

const getAuth = () => {
    switch (DeviceType) {
        case 0:
            return window.Android.getAuth()
        case 1:
            let message = {
                action: "getAuth"
            }
            window.webkit.messageHandlers.iOS.postMessage(message)
            return false
        default:
            return sessionStorage.getItem("auth")
    }
}

const setAuth = (auth) => {
    switch (DeviceType) {
        case 0:
            window.Android.setAuth(auth)
            break
        case 1:
            let message = {
                action: "setAuth",
                payload: auth
            }
            window.webkit.messageHandlers.bridge.postMessage(message)
            break
        default:
            sessionStorage.setItem("auth", auth)
    }
}

const getInfo = () => {
    return {
        name: getDeviceName(),
        fmsToken: getFMSToken(),
        type: DeviceType,
        referralUrl: getReferralUrl()
    }
}

const update = () => {
    switch (DeviceType) {
        case 0:
            window.Android.update()
            break
        default:
    }
}

const Device = {
    getFMSToken: getFMSToken,
    getReferralUrl: getReferralUrl,
    getAuth: getAuth,
    setAuth: setAuth,
    getInfo: getInfo,
    update: update,
    saveReferralUrl: saveReferralUrl,
    types: {
        android: 0,
        ios: 1,
        web: 2
    }
}

export default Device
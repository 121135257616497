import React from 'react'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
            <label>New Password</label>
                <input autoFocus={true} type="password" name="password" value={props.password} placeholder="New Password" onChange={props.onChange} />
                <span className="error">{props.errors.password}</span>
            </div>
            <div className="form-input">
                <label>Confirm Password</label>
                <input type="password" name="password2" value={props.password2} placeholder="Confirm New Password" onChange={props.onChange} />
                <span className="error">{props.errors.password2}</span>
            </div>
            <div className="actions">
                <button className="btn action">Save</button>
            </div>
        </form>
    )
}

export default Form
import { InternetSetAccounts } from '../../../../redux/reducers/internet/actions'
import Requests from '../../../../utils/Requests'

const getDefaultState = instance => {
    return {
        loadingAccounts: false,
        accounts: [],
        showError: false,
        alertError: {}
    }
}

const loadAccounts = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/services/internet/skysenx/clients")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadAccountsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadAccountsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingAccounts: false,
                accounts: response.data.clients
            })
            instance.props.dispatch(InternetSetAccounts(response.data.clients))
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingAccounts: false,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadAccounts,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const AccountsWorker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadAccounts: () => loadAccounts(instance)
    }
}

export default AccountsWorker
import React from 'react'
import { Switch, Route } from "react-router-dom"
import AddressController from './addresses/address.controller'
import DeliverableController from './deliverables/deliverables.controller'
import Cart from './cart/cart.page'
import Checkout from './checkout/checkout.page'

const MarketPlaceController = () => {
    return (
        <div className="marketplace">
            <Switch>
                <Route path="/marketplace/addresses" component={AddressController} />
                <Route path="/marketplace/checkout" component={Checkout} />
                <Route path="/marketplace/cart" component={Cart} />
                <Route path="/marketplace" component={DeliverableController} />
            </Switch>
        </div>
    )
}

export default MarketPlaceController
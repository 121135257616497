import Requests from '../../../utils/Requests'
import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        resetting: false,
        tab: 0,
        password: "",
        password2: "",
        flag: {
            type: "",
            text: ""
        },
        showError: false,
        alertError: {},
        errors: {}
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.password2, (error) => instance.state.errors.password2 = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
    let validate = validation.validate() && instance.state.password === instance.state.password2
    let validationError = instance.state.password === instance.state.password2? "":"Password do not match"

    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : validationError
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const reset = (instance) => {
    let data = {
        token: instance.props.match.params.token,
        password: instance.state.password
    }
    Requests(instance.props)
        .post()
        .withUrl("/auth/customer/reset")
        .withData(data)
        .then(response => {
            handleResetResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleResetResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                tab: 1,
                resetting: false
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        resetting: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onReset,
            onClose: () => instance.props.history.push('/auth/login')
        }
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        reset: () => reset(instance)
    }
}

export default Worker
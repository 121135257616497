import Requests from "../../../../utils/Requests"

const getDefaultState = () => {
    return {
        cabletvOrder: getCabletvOrder(),
        loadingWallet: false,
        loadingDedicatedNuban: false,
        placingOrder: false,
        showCreateDedicatedNuban: false,
        wallet: {
            balance: 0
        },
        dedicatedNuban: null,
        showError: false,
        alertError: {}
    }
}

const getCabletvOrder = () => {
    let cabletvOrder = sessionStorage.getItem("cabletvOrder")
    if (!cabletvOrder) {
        window.location = "/cabletv/order"
        return null
    }
    return JSON.parse(cabletvOrder);
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const getTotal = instance => {
    return instance.state.cabletvOrder.bouquet.amount * 100
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                showDedicatedNuban: false,
                wallet: response.data.wallet
            }, () => {
                if (response.data.wallet.balance < getTotal(instance)) {
                    instance.service.loadDedicatedNuban()
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const loadDedicatedNuban = instance => {

    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                dedicatedNuban: response.data.dedicatedNuban,
                showDedicatedNuban: true
            })
            break
        case 16200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                showCreateDedicatedNuban: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const placeOrder = instance => {
    let data = {
        decoderId: instance.state.cabletvOrder.decoder.id,
        cycle: 1,
        bouquetName: instance.state.cabletvOrder.bouquet.name,
        bouquetCode: instance.state.cabletvOrder.bouquet.code,
        amount: instance.state.cabletvOrder.bouquet.amount * 100,
        renewal: instance.state.cabletvOrder.renewal
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/order/cabletv/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPlaceOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPlaceOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                placingOrder: false,
                showPaymentSuccess: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingWallet: false,
        placingOrder: false,
        loadingDedicatedNuban: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadWallet: () => loadWallet(instance),
        getTotal: () => getTotal(instance),
        placeOrder: () => placeOrder(instance),
        loadDedicatedNuban: () => loadDedicatedNuban(instance)
    }
}

export default Service
import React from 'react'
import { withRouter } from 'react-router-dom'
import './events.styles.scss'
import Event from './event/event.component'
import Service from './events.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'
import EmptyList from '../../../components/empty-list/empty-list.component'
import SearchBox from '../../../components/inputs/searchbox/searchbox.component'

class Events extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Ticketing | Events'
        this.onLoadEvents()
    }

    onLoadEvents = () => {
        this.setState({
            ...this.state,
            loadingEvents: true,
            showError: false
        }, () => this.service.loadEvents())
    }

    onSearchChanged = e => {
        this.setState({
            ...this.state,
            loadingEvents: true,
            showError: false,
            searchKeywords: e.target.value 
        }, () => this.service.loadEvents())
    }

    render() {
        return (
            <div className="events">
                {this.state.loadingEvents && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                <Header title="Events" backUrl="/dashboard"/>
                <div className="records">
                <SearchBox name="search" placeholder="enter keyword of Event" onChange={this.onSearchChanged} />
                    {
                        this.state.events.map((event, key) => <Event key={key} {...event} />)
                    }
                </div>
                {
                    !this.state.loadingEvents && this.state.events.length === 0 && <EmptyList title="oops! no event found" />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Events))
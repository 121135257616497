import { SET_BENEFICIARIES} from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case SET_BENEFICIARIES:
            return setBeneficiaries(state, action.payload)
        default:
            return state
    }
}

const setBeneficiaries = (state, payload) => {
    state.beneficiaries = payload
    state.cache.beneficiaries = new Date().getTime()
    return state
}

export default Reducer
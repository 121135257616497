import React from 'react'
import { withRouter } from 'react-router-dom'
import './meters.styles.scss'
import Header from '../../../../components/header/header.component'
import Meter from './meter/meter'
import Service from './meters.service'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import '../common.styles.scss'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import SubHeader from '../../../../components/sub-header/sub-header.component'
import DeleteMeter from './components/delete/delete.component'
import EmptyList from '../../../../components/empty-list/empty-list.component'
import SearchBox from '../../../../components/inputs/searchbox/searchbox.component'

class Meters extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Electricity | Saved Meters'
        this.onLoadMeters()
    }

    onLoadMeters = () => {
        this.setState({
            ...this.state,
            loadingMeters: true,
            showError: false
        }, () => this.service.loadMeters())
    }

    onMeterSelected = meter => {
        sessionStorage.setItem("currentMeter", JSON.stringify(meter))
        this.props.history.push('/utility/electricity/order')
    }

    onDeleteMeter = meter => {
        this.setState({
            ...this.state,
            showDeleteMeter: true,
            meterToDelete: meter
        })
    }

    onMeterDeleted = () => {
        let meters = this.state.meters.filter(meter => meter.id !== this.state.meterToDelete.id)
        this.setState({
            ...this.state,
            meters: meters,
            showDeleteMeter: false,
            meterToDelete: null
        })
    }

    onCancelDeleteMeter = () => {
        this.setState({
            ...this.state,
            showDeleteMeter: false,
            meterToDelete: null
        })
    }

    onSearchChanged = e => {
        this.setState({
            ...this.state,
            searchKeywords: e.target.value 
        })
    }

    render() {
        return (
            <div className="meters">
                {this.state.loadingMeters && <Dialog><Loading></Loading></Dialog>}
                {this.state.showDeleteMeter && <Dialog><DeleteMeter meter={this.state.meterToDelete} onDeleted={this.onMeterDeleted} onCancel={this.onCancelDeleteMeter} /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                <Header title="Electricity" backUrl="/dashboard" />
                <SubHeader title="Saved Meters" url="/utility/electricity/meter/create" />
                <div className="records">
                    {
                        this.state.meters.length > 3 && <SearchBox name="search" placeholder="Search by nickname, meter name" onChange={this.onSearchChanged} />
                    }
                    {
                        this.state.meters.filter(meter => meter.name.toLowerCase().includes(this.state.searchKeywords.toLowerCase()) || meter.alias.toLowerCase().includes(this.state.searchKeywords.toLowerCase())).map((meter, key) => <Meter onClick={() => this.onMeterSelected(meter)} onDeleteClick={() => this.onDeleteMeter(meter)} key={key} {...meter} />)
                    }
                </div>
                {
                    !this.state.loadingMeters && this.state.meters.length === 0 && <EmptyList title="oops! no saved beneficiary" />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cabletv: state.cabletv,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Meters))
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './common.styles.scss'
import Required from './required/required.page'

const Controller = () => {
    document.title = 'Update'
    return (
        <div className="update">
            <Switch>
                <Route path="/update/required" component={Required} />
            </Switch>
        </div>
    )
}

export default Controller
import Requests from "../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        mobileTopUpOrder: getMobileTopUpOrder(),
        loadingWallet: true,
        loadingDedicatedNuban: false,
        placingOrder: false,
        showCreateDedicatedNuban: false,
        wallet: {
            balance: 0
        },
        dedicatedNuban: null,
        showError: false,
        alertError: {}
    }
}

const getMobileTopUpOrder = () => {
    let mobileTopUpOrder = sessionStorage.getItem("mobileTopUpOrder")
    if (!mobileTopUpOrder) {
        window.location = "/utility/airtime/order"
        return null
    }
    return JSON.parse(mobileTopUpOrder);
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                showDedicatedNuban: false,
                wallet: response.data.wallet
            }, () => {
                if (response.data.wallet.balance < (instance.state.mobileTopUpOrder.amount * 100)) {
                    instance.service.loadDedicatedNuban()
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const loadDedicatedNuban = instance => {

    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                dedicatedNuban: response.data.dedicatedNuban,
                showDedicatedNuban: true
            })
            break
        case 16200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                showCreateDedicatedNuban: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const placeOrder = instance => {
    let data = {
        ...instance.state.mobileTopUpOrder,
        type: instance.state.mobileTopUpOrder.orderType,
        amount: Number(instance.state.mobileTopUpOrder.amount) * 100
    }

    if(instance.state.mobileTopUpOrder.orderType === 1){
        data.dataBundleCode = instance.state.mobileTopUpOrder.dataBundle.code
        data.dataBundleDescription = instance.state.mobileTopUpOrder.dataBundle.name
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/order/utility/mobile-top-up/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPlaceOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPlaceOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                placingOrder: false,
                showPaymentSuccess: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingWallet: false,
        placingOrder: false,
        loadingDedicatedNuban: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadWallet: () => loadWallet(instance),
        placeOrder: () => placeOrder(instance),
        loadDedicatedNuban: () => loadDedicatedNuban(instance)
    }
}

export default Service
import React from 'react'
import './distributor.styles.scss'
import { withRouter } from 'react-router-dom'
import iconBEDC from '../../../../../../../assets/icons/bedc-only.svg'
import iconArrowDown from '../../../../../../../assets/icons/arrow-bottom.svg'
import iconEKEDC from '../../../../../../../assets/icons/ekedc.svg'
import iconIBEDC from '../../../../../../../assets/icons/ibedc.svg'
import iconIKEDC from '../../../../../../../assets/icons/ikedc.svg'
import iconJED from '../../../../../../../assets/icons/jed.svg'
import iconKEDCO from '../../../../../../../assets/icons/kedco.svg'
import iconKAEDCO from '../../../../../../../assets/icons/kaedco.svg'
import iconPHED from '../../../../../../../assets/icons/phed.svg'
import iconAEDC from '../../../../../../../assets/icons/aedc.svg'
import iconEEDC from '../../../../../../../assets/icons/eedc.svg'
import DistributorChooser from './distributor-chooser/distributor-chooser.component'

class Distributor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            showDistributorChooser: false
        }
    }

    toggleShowDistributorChooser = () => {
        this.setState({
            ...this.state,
            showDistributorChooser: !this.state.showDistributorChooser
        })
    }

    getIconInfo = () => {
        switch (this.props.distributor) {
            case 0:
                return {
                    name: "BEDC",
                    src: iconBEDC
                }
            case 1:
                return {
                    name: "IKEDC",
                    src: iconIKEDC
                }
            case 2:
                return {
                    name: "EKEDC",
                    src: iconEKEDC
                }
            case 3:
                return {
                    name: "PHED",
                    src: iconPHED
                }
            case 4:
                return {
                    name: "AEDC",
                    src: iconAEDC
                }
            case 5:
                return {
                    name: "KEDCO",
                    src: iconKEDCO
                }
            case 6:
                return {
                    name: "JED",
                    src: iconJED
                }
            case 7:
                return {
                    name: "IBEDC",
                    src: iconIBEDC
                }
            case 8:
                return {
                    name: "KAEDCO",
                    src: iconKAEDCO
                }
            case 9:
                return {
                    name: "EEDC",
                    src: iconEEDC
                }
            default:
                return {
                    name: "BEDC",
                    src: iconBEDC
                }
        }
    }

    onSelect = distributor => {
        this.props.onChange({
            target: {
                name: "distributor",
                value: distributor
            }
        })
        this.toggleShowDistributorChooser()
    }

    render() {
        let iconInfo = this.getIconInfo()
        return (
            <>
                {this.state.showDistributorChooser && <DistributorChooser onSelect={this.onSelect} onClose={this.toggleShowDistributorChooser} />}
                <div className="distributor" onClick={this.toggleShowDistributorChooser}>
                    {
                        this.props.distributor !== "" && <>
                            <div>
                                <img alt="icon" src={iconInfo.src} />
                            </div>
                        </>
                    }
                    <h4>{this.props.distributor === "" ? "Select Power Distributor" : iconInfo.name}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(Distributor)
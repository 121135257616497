import React from 'react'
import { Link } from 'react-router-dom'
import iconBack from '../../../assets/icons/arrow-back.svg'
import './terms.styles.scss'

const Terms = () => {
    document.title = 'Terms & Conditions'
    return (
        <>
            <header className="app">
                <Link className="back" onClick={() => window.location = "/dashboard"}><img alt="back" src={iconBack} /></Link>
                <p>Terms & Conditions</p>
            </header>
            <div className="terms">
                <div className="wrapper">
                    <iframe title="Terms & Conditions" src="https://app.dailyruns.ng/pages/terms.html" />
                </div>
            </div>
        </>
    )
}

export default Terms
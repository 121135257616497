import React from 'react'
import NumberFormat from 'react-number-format'
import './deliverable-item.styles.scss'

const DeliverableItem = props => {
    return (
        <div className="deliverable-item" onClick={props.onClick}>
            <div>
                <h4>{props.name}</h4>
                <p>{props.description}</p>
            </div>
            <NumberFormat value={props.amount/100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>N{value}</strong>} />
        </div>
    )
}

export default DeliverableItem
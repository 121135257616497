import Requests from "../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        electricityOrder: getElectricityOrder(),
        loadingWallet: true,
        loadingDedicatedNuban: false,
        loadingConvinenceFee: true,
        placingOrder: false,
        showCreateDedicatedNuban: false,
        wallet: {
            balance: 0
        },
        convinenceFee: 0,
        dedicatedNuban: null,
        showError: false,
        alertError: {}
    }
}

const getElectricityOrder = () => {
    let electricityOrder = sessionStorage.getItem("electricityOrder")
    if(!electricityOrder){
        window.location = "/utility/electricity/order"
        return null
    }
    return JSON.parse(electricityOrder);
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const getTotal = instance => {
    return (Number(instance.state.electricityOrder.amount) + Number(instance.state.convinenceFee)) * 100
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                showDedicatedNuban: false,
                wallet: response.data.wallet
            }, () => {
                if (response.data.wallet.balance < getTotal(instance)) {
                    instance.service.loadDedicatedNuban()
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const loadConvinenceFee = instance => {
    let data = {
        distributor: instance.state.electricityOrder.meter.distributor,
        amount: instance.state.electricityOrder.amount * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/order/utility/electricity/convenient")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadConvinenceFeeResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadConvinenceFeeResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingConvinenceFee: false,
                convinenceFee: response.data.amount / 100
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const loadDedicatedNuban = instance => {

    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                dedicatedNuban: response.data.dedicatedNuban,
                showDedicatedNuban: true
            })
            break
        case 16200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                showCreateDedicatedNuban: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const placeOrder = instance => {
    let data = {
        meterId: instance.state.electricityOrder.meter.id,
        amount: Number(instance.state.electricityOrder.amount) * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/order/utility/electricity/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPlaceOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPlaceOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                placingOrder: false,
                showPaymentSuccess: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingWallet: false,
        placingOrder: false,
        loadingDedicatedNuban: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadWallet: () => loadWallet(instance),
        loadConvinenceFee: () => loadConvinenceFee(instance),
        getTotal: () => getTotal(instance),
        placeOrder: () => placeOrder(instance),
        loadDedicatedNuban: () => loadDedicatedNuban(instance)
    }
}

export default Service
import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule, PhoneValidationRule } from '../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../utils/UIHelper'
import Requests from '../../../utils/Requests'

const getDefaultState = () => {
    return {
        creating: false,
        tab: 0,
        firstname: "",
        lastname: "",
        password: "",
        phone: "",
        email: "",
        emailVerificationId: "",
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => instance.state.errors.firstname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => instance.state.errors.lastname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => instance.state.errors.phone = error, { allowNull: false, error: "Invalid" })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => instance.state.errors.email = error, { allowNull: false, error: "Invalid" })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })

    instance.setState({
        ...instance.state,
        errors: errors
    }, () => {
        for (let error in instance.state.errors) {
            if (instance.state.errors[error].length > 0) {
                ScrollIntoViewById(error)
                return
            }
        }
    })
    return validation.validate()
}

const create = (instance) => {
    let data = {
        firstname: instance.state.firstname.trim(),
        lastname: instance.state.lastname.trim(),
        password: instance.state.password,
        phone: instance.state.phone.trim(),
        email: instance.state.email.trim().toLowerCase()
    }
    Requests(instance.props)
        .post()
        .withUrl("/auth/customer/create")
        .withData(data)
        .then(response => {
            handleCreateResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                tab: 1,
                creating: false
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.status.message
                }
            })
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreate,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        create: () => create(instance)
    }
}

export default Worker
import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingEvent: true,
        showError: false,
        quantity: {
            label: "1",
            value: 1
        },
        event: null,
        tags: [],
        ticketType: {
            name: "Choose Ticket Type",
            amount: 0,
            id: "",
        },
        alertError: {}
    }
}

const loadEvent = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/ticketing/event/code")
        .withAuthorization(instance.props.auth.authorization)
        .withData({eventCode: instance.props.match.params.eventCode})
        .then(response => {
            handleLoadEventResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadEventResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingEvent: false,
                event: response.data.event
            }, () => {
                if(instance.state.event.ticketTypes.length === 1){
                    instance.onTicketTypeChange(instance.state.event.ticketTypes[0])
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingEvent: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDecoder,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadEvent: () => loadEvent(instance)
    }
}

export default Service
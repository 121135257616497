import React from 'react'
import NumberFormat from 'react-number-format'
import StatusLabels from '../../data/status-label'
import Logo from '../../components/brands/logo/logo.component'

const Electricity = props => {
    return (
        <>
            <div className="top">
                {getLogo(props.electricity.meter.distributor)}
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Meter Number</p>
                    <p>{props.electricity.meter.number}</p>
                </div>
                <div className="info">
                    <p>Meter Name</p>
                    <p>{props.electricity.meter.name}</p>
                </div>
                <div className="info">
                    <p>Meter Type</p>
                    <p>{props.electricity.meter.type === 0 ? "Prepaid" : "Postpaid"}</p>
                </div>
                <div className="info">
                    <p>Nickname</p>
                    <p>{props.electricity.meter.alias}</p>
                </div>
                {
                    props.electricity.token !== null &&
                    <div className="info">
                        <p>Token</p>
                        <p><strong>{props.electricity.token}</strong></p>
                    </div>
                }
                {
                    props.electricity.resetTokens !== null &&
                    <div className="info">
                        <p>Reset Tokens</p>
                        <p><strong>{props.electricity.resetTokens.replace(",", " ")}</strong></p>
                    </div>
                }
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
            </div>
        </>
    )
}

const getLogo = distributor => {
    switch (distributor) {
        case 0:
            return <Logo name="bedc" />
        case 1:
            return <Logo name="ikedc" />
        case 2:
            return <Logo name="ekedc" />
        case 3:
            return <Logo name="phed" />
        case 4:
            return <Logo name="aedc" />
        case 5:
            return <Logo name="kedco" />
        case 6:
            return <Logo name="jed" />
        case 7:
            return <Logo name="ibedc" />
        case 8:
            return <Logo name="kaedco" />
        case 9:
            return <Logo name="eedc" />
        default:
            return <Logo name="bedc" />
    }
}

export default Electricity
import Requests from "../../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingPlans: true,
        plans: [],
        tab: 0,
        showError: false,
        alertError: {}
    }
}

const loadPlans = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/internet/skysenx/plans")
        .withAuthorization(instance.props.auth.authorization)
        .withData({clientId: instance.props.account.id})
        .then(response => {
            handleLoadPlansResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPlansResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingPlans: false,
                plans: response.data.plans
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}
const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadPlans,
            onClose: () => instance.props.history.push('/internet/skysenx/order')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadPlans: () => loadPlans(instance)
    }
}

export default Service
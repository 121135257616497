import Requests from '../../../../../utils/Requests'
import Validation from '../../../../../data/validation/validation'
import { StringValidationRule } from '../../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        username: "",
        password: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.username, (error) => errors.username = error, { min: { value: 2, error: "Too short" }, max: { value: 10, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => errors.password = error, { min: { value: 2, error: "Too short" }, max: { value: 10, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Please enter username and password"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addAccount = instance => {

    let data = {
        userId: instance.state.username.trim(),
        password: instance.state.password
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/services/internet/skysenx/client/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleAddAccountResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleAddAccountResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            sessionStorage.setItem("currentAccount", JSON.stringify(response.data.client))
            instance.props.history.push('/internet/skysenx/order')
            break
        case 13000:
            handleError(instance, "Account already exists")
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onAddAccount,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addAccount: () => addAccount(instance)
    }
}

export default Service
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './register.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Form from './form.page'
import FormHelper from '../../../utils/FormHelper'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'

class Register extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Auth | Register'
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.onCreate()
    }

    onCreate = () => {
        this.service.isValid() && this.setState({
            ...this.state,
            creating: true
        }, () => this.service.create())
    }

    getVerificationComponent = () => {
        return (
            <div className="complete">
                <h3>Verification Required</h3>
                <div className="flag success">
                    Verification link sent to your email
                </div>
                <br />
                <p>Click <Link to="/auth/login">here</Link> to login after verification</p>
            </div>
        )
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return this.getVerificationComponent()
            default:
                return <div className="register">
                    {this.state.creating && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    <h3>Create Account</h3>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state} />
                    <div className="new-account">
                        <p>Already have an account?</p>
                        <Link className="signup" to='/auth/login'>Sign In</Link>
                    </div>
                </div>
        }
    }

    render() {
        return (
            <>
                <Header title="" backUrl="/auth/login" />
                {this.getCurrentTab()}
            </>
        )
    }
}

export default connect(null)(withRouter(Register))
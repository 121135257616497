import React from 'react'
import './searchbox.styles.scss'
import iconSearch from '../../../assets/icons/search.svg'

const SearchBox = ({ name, placeholder, onChange }) => {
    return (
        <div className="searchbox">
            <img alt="search" src={iconSearch} />
            <input type="text" placeholder={placeholder} name={name} onChange={onChange} />
        </div>
    )
}

export default SearchBox
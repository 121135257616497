import React from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import { connect } from 'react-redux'
import Form from './form'
import MeterInfo from '../components/meter-info/meter-info.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Electricity | Prepare Order'
    }

    onAmountChanged = e => {
        this.setState({
            ...this.state,
            amount: e.target.value
        })
    }

    onBuy = () => {
        if (this.service.isValid()) {
            let electricityOrder = {
                meter: this.state.meter,
                amount: this.state.amount
            }

            sessionStorage.setItem("electricityOrder", JSON.stringify(electricityOrder))
            this.props.history.push('/utility/electricity/order/create')
        }
    }

    render() {
        if(this.state.meter === null){
            return <></>
        }
        return (
            <>
                <Header title="Electricity" backUrl="/utility/electricity" />
                <div className="order">
                    <MeterInfo {...this.state.meter} />
                    <br />
                    <Form onChange={this.onAmountChanged} {...this.state} />
                    <button onClick={this.onBuy} className="renew">Buy</button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        electricity: state.electricity
    }
}

export default connect(mapStateToProps)(withRouter(Order))
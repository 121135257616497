import Requests from "../../../utils/Requests"

const getDefaultState = instance => {
    return {
        loadingOrders: false,
        orders: [],
        showError: false,
        alertError: {}
    }
}

const loadOrders = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/orders")
        .withAuthorization(instance.props.auth.authorization)
        .withData({year: 2020})
        .then(response => {
            handleLoadOrdersResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadOrdersResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrders: false,
                orders: response.data.orders
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingOrders: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadOrders,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadOrders: () => loadOrders(instance)
    }
}

export default Service
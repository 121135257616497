import React from "react"
import NumberFormat from "react-number-format"

const DeliveryOption = props => {
    return (
        <div className="delivery-option-select" onClick={props.onClick}>
            <h4>{props.name}</h4>
            <NumberFormat value={props.amount/100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>N{value}</strong>} />
        </div>
    )
}

export default DeliveryOption
import React from "react"
import iconClose from '../../../../../assets/icons/close.svg'
import Dialog from "../../../../../components/dialog/dialog.component"
import './ticket-type-chooser.styles.scss'
import TicketType from "./ticket-type/ticket-type.component"

const TicketTypeChooser = props => {
    return (
        <Dialog>
            <div className="ticket-type-chooser">
                <h3>
                    <span>Select Ticket Type</span>
                    <img alt="close" src={iconClose} onClick={props.onClose} />
                </h3>
                <div className="ticket-types">
                    {
                        props.ticketTypes.map((ticketType, key) => <TicketType key={key} {...ticketType} onClick={() => this.props.onSelect(ticketType)}/>)
                    }
                </div>
            </div>
        </Dialog>
    )
}

export default TicketTypeChooser
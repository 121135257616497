const getDefaultState = () => {
    const account = getAccount()
    return {
        account: account,
        plan: typeof account.plan !== 'undefined'? account.plan: {
            name: "Select Internet Plan",
            amount: 0,
            validity: 30
        },
        renewCycle: {
            label: "1 Month",
            value: 1
        },
        processing: false
    }
}

const getAccount = () => {
    let account = sessionStorage.getItem("currentAccount")
    if(!account){
        window.location = "/internet/skysenx"
        return null
    }
    return JSON.parse(account);
}

const Service = () => {
    return {
        getDefaultState: () => getDefaultState()
    }
}

export default Service
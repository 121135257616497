import React from 'react'
import './deliverable.styles.scss'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'

const Deliverable = props => {
    return (
        <div className="deliverable" onClick={() => props.history.push("/marketplace/" + props.sku.toLowerCase())}>
            <div>
                <h4>{props.name}</h4>
                <h6>{props.description}</h6>
                <p>
                <i>from</i> <NumberFormat value={props.lowestAmount / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>N{value}</span>} />
                </p>
            </div>
            <img alt="" src={props.imageUrl}/>
        </div>
    )
}

export default withRouter(Deliverable)
import React from 'react'
import './meter.scss'
import iconArrowForward from '../../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'
import iconBEDC from '../../../../../assets/icons/bedc-only.svg'
import iconEKEDC from '../../../../../assets/icons/ekedc.svg'
import iconIBEDC from '../../../../../assets/icons/ibedc.svg'
import iconIKEDC from '../../../../../assets/icons/ikedc.svg'
import iconJED from '../../../../../assets/icons/jed.svg'
import iconKEDCO from '../../../../../assets/icons/kedco.svg'
import iconKAEDCO from '../../../../../assets/icons/kaedco.svg'
import iconPHED from '../../../../../assets/icons/phed.svg'
import iconAEDC from '../../../../../assets/icons/aedc.svg'
import iconEEDC from '../../../../../assets/icons/eedc.svg'
import iconDelete from '../../../../../assets/icons/delete.svg'

const Meter = props => {
    return (
        <div className="meter">
            <div className="content" onClick={props.onClick}>
                <div>
                    <img alt="icon" src={getIcon(props.distributor)} />
                </div>
                <div>
                    <h4>{props.alias}</h4>
                    <p>
                        {props.number}
                    </p>
                </div>
                <img src={iconArrowForward} alt="icon" />
            </div>
            <img src={iconDelete} alt="delete" onClick={props.onDeleteClick} />
        </div>
    )
}

const getIcon = distributor => {
    switch (distributor) {
        case 0:
            return iconBEDC
        case 1:
            return iconIKEDC
        case 2:
            return iconEKEDC
        case 3:
            return iconPHED
        case 4:
            return iconAEDC
        case 5:
            return iconKEDCO
        case 6:
            return iconJED
        case 7:
            return iconIBEDC
        case 8:
            return iconKAEDCO
        case 9:
            return iconEEDC
        default:
            return iconBEDC
    }
}

export default withRouter(Meter)
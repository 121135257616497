const getDefaultState = () => {
    return {
        item: getItem()
    }
}

const getItem = () => {
    let currentItem = sessionStorage.getItem("currentItem")
    if(!currentItem){
        window.location = "/marketplace"
        return null
    }
    return JSON.parse(currentItem);
}

const Service = () => {
    return {
        getDefaultState: () => getDefaultState()
    }
}

export default Service
import { combineReducers } from 'redux'
import menu from './menu/reducer'
import auth from './auth/reducer'
import internet from './internet/reducer'
import cabletv from './cabletv/reducer'
import electricity from './electricity/reducer'
import airtime from './airtime/reducer'

const Reducer = combineReducers({
    menu,
    auth,
    internet,
    cabletv,
    electricity,
    airtime
})

export default Reducer
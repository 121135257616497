import Requests from "../../../utils/Requests"
import Cart from '../cart.service'

const getDefaultState = instance => {
    return {
        deliveryAddress: getDeliveryAddress(),
        items: instance.cart.items(),
        itemsWeight: instance.cart.itemsWeight(),
        deliveryFee: 0,
        deliveryOption: null,
        loadingWallet: true,
        loadingDedicatedNuban: false,
        placingOrder: false,
        showCreateDedicatedNuban: false,
        wallet: {
            balance: 0
        },
        dedicatedNuban: null,
        showError: false,
        alertError: {}
    }
}

const getDeliveryAddress = () => {
    let deliveryAddress = sessionStorage.getItem("deliveryAddress")
    if(!deliveryAddress){
        window.location = "/marketplace"
        return null
    }
    return JSON.parse(deliveryAddress);
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const getTotal = instance => {
    return instance.getCartTotal() + instance.state.deliveryFee
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                showDedicatedNuban: false,
                wallet: response.data.wallet
            }, () => {
                if (response.data.wallet.balance < getTotal(instance)) {
                    instance.service.loadDedicatedNuban()
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const loadDedicatedNuban = instance => {

    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                dedicatedNuban: response.data.dedicatedNuban,
                showDedicatedNuban: true
            })
            break
        case 16200:
            instance.setState({
                ...instance.state,
                loadingDedicatedNuban: false,
                showCreateDedicatedNuban: true
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const placeOrder = instance => {
    let data = {
        items: convertToOrderItems(instance.state.items),
        deliveryOptionId: instance.state.deliveryOption.id,
        deliveryAddressId: instance.state.deliveryAddress.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/customer/marketplace/order/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPlaceOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const convertToOrderItems = items => {
    let orderItems = []
    for(let i = 0; i < items.length; i++){
        orderItems.push({
            deliverableItemId: items[i].id,
            quantity: items[i].quantity
        })
    }
    return orderItems
}

const handleLoadPlaceOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                placingOrder: false,
                showPaymentSuccess: true
            })
            instance.cart.clear()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingWallet: false,
        placingOrder: false,
        loadingDedicatedNuban: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.props.history.push('/marketplace/cart'),
            onClose: () => instance.props.history.push('/marketplace/cart')
        }
    })
}

const Service = instance => {
    instance.cart = Cart()
    return {
        getDefaultState: () => getDefaultState(instance),
        loadWallet: () => loadWallet(instance),
        getTotal: () => getTotal(instance),
        placeOrder: () => placeOrder(instance),
        loadDedicatedNuban: () => loadDedicatedNuban(instance)
    }
}

export default Service
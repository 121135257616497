import React from 'react'
import Menu from './menu/menu'
import './default.styles.scss'

const DefaultTemplate = props => {
    return (
        <div className="app">
            <div className="shade"></div>
            {props.children}
            <Menu/>
        </div>
    )
}

export default DefaultTemplate
import React from "react"

const Area = props => {
    return (
        <div className="area-select" onClick={props.onClick}>
            <h4>{props.route.name} - {props.name}</h4>
        </div>
    )
}

export default Area
import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        creating: false,
        flag: {
            type: "",
            text: ""
        },
        showError: false,
        alertError: {}
    }
}

const createDedicatedNuban = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/billing/payment/gateway/paystack/dedicated-nuban/create")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleDedicatedNubanResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDedicatedNubanResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false
            })
            instance.props.onCreated(response.data.dedicatedNuban)
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateClick,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        createDedicatedNuban: () => createDedicatedNuban(instance)
    }
}

export default Service
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Service from './reset.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Form from './form.page'
import FormHelper from '../../../utils/FormHelper'

class Reset extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Auth | Setup Password'
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.onReset()
    }

    onReset = () => {
        this.setState({
            ...this.state,
            verifying: true
        }, () => this.service.reset())
    }

    getCompletedComponent = () => {
        return (
            <div className="complete">
                <h3>Password Updated</h3>
                <br />
                <p>Click <Link to="/auth/login">here</Link> to login</p>
            </div>
        )
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return this.getCompletedComponent()
            default:
                return <div className="setup">
                    {this.state.verifying && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    <h3>Setup New Password</h3>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state} />
                </div>
        }
    }

    render() {
        return this.getCurrentTab()
    }
}

export default withRouter(Reset)
import React from 'react'
import { Switch, Route } from "react-router-dom"
import Accounts from './accounts/account.controller'
import Order from './order/order.page'
import CreateOrder from './order/create/create.page'
import About from './about/about.page'

const SkysenxController = () => {
    return (
        <div className="skysenx">
            <Switch>
            <Route path="/internet/skysenx/order/create" component={CreateOrder} />
                <Route path="/internet/skysenx/order" component={Order} />
                <Route path="/internet/skysenx/about" component={About} />
                <Route path="/internet/skysenx" component={Accounts} />
            </Switch>
        </div>
    )
}

export default SkysenxController
import { SET_METERS} from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case SET_METERS:
            return setMeters(state, action.payload)
        default:
            return state
    }
}

const setMeters = (state, payload) => {
    state.meters = payload
    state.cache.meters = new Date().getTime()
    return state
}

export default Reducer
import React from 'react'
import './quantity-modifier.styles.scss'

const QuantityModifier = props => {
    return (
        <div className="quantity-modifier">
            <div onClick={props.onDecrease}>-</div>
            <span>{props.value}</span>
            <div onClick={props.onIncrease}>+</div>
        </div>
    )
}

export default QuantityModifier
import React from 'react'
import './decoder.scss'
import iconDstv from '../../../../assets/icons/dstv-white.svg'
import iconGotv from '../../../../assets/icons/gotv.svg'
import iconStartimes from '../../../../assets/icons/startimes.svg'
import iconDelete from '../../../../assets/icons/delete.svg'
import iconArrowForward from '../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'

const Decoder = props => {
    let iconInfo = getIcon(props.type)
    return (
        <div className="decoder">
            <div className="content" onClick={props.onClick}>
                <div className={iconInfo.class}>
                    <img alt="icon" src={iconInfo.src} />
                </div>
                <div>
                    <h4>{props.alias}</h4>
                    <p>
                        {props.number}
                    </p>
                </div>
                <img src={iconArrowForward} alt="icon" />
            </div>
            <img src={iconDelete} alt="delete" onClick={props.onDeleteClick} />
        </div>
    )
}

const getIcon = type => {
    switch (type) {
        case 0:
            return {
                class: "dstv",
                src: iconDstv
            }
        case 1:
            return {
                class: "gotv",
                src: iconGotv
            }
        default:
            return {
                class: "startimes",
                src: iconStartimes
            }
    }
}

export default withRouter(Decoder)
import React from 'react'
import { Switch, Route } from "react-router-dom"
import SkysenxController from './skysenx/skysenx.controller'

const InternetController = () => {
    return (
        <div className="internet">
            <Switch>
                <Route path="/internet" component={SkysenxController} />
            </Switch>
        </div>
    )
}

export default InternetController
import React from 'react'
import { Switch, Route } from "react-router-dom"
import Meters from './meters/meter.controller'
import Order from './order/order.page'
import CreateOrder from './order/create/create.page'

const ElectricityController = () => {
    return (
        <div className="electricity">
            <Switch>
                <Route path="/utility/electricity/order/create" component={CreateOrder} />
                <Route path="/utility/electricity/order" component={Order} />
                <Route path="/utility/electricity" component={Meters} />
            </Switch>
        </div>
    )
}

export default ElectricityController
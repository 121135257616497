import React from 'react'
import Service from './checkout.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import DedicatedNuban from '../../../components/wallet/dedicated-nuban/dedicated-nuban.component'
import './checkout.styles.scss'
import iconNaira from '../../../assets/icons/naira-dark.svg'
import NumberFormat from 'react-number-format'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import CreateDedicatedNuban from '../../../components/wallet/create-dedicate-nuban/create-dedicate-nuban.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'
import PaymentSuccess from '../../../components/payment-success/payment-success.component'
import DeliveryOption from './delivery-option/delivery-option.component'

class Checkout extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Marketplace | Checkout'
        this.service.loadWallet()
    }

    onReloadClick = () => {
        this.setState({
            ...this.state,
            loadingWallet: true,
            showError: false
        }, () => this.service.loadWallet())
    }

    onPlaceOrderClick = () => {
        if (this.state.deliveryOption !== null && this.state.placingOrder !== true) {
            this.setState({
                ...this.state,
                placingOrder: true,
                showError: false
            }, () => this.service.placeOrder())
        }
    }

    onDedicatedNubanCreated = dedicatedNuban => {
        this.setState({
            ...this.state,
            dedicatedNuban: dedicatedNuban,
            showDedicatedNuban: true,
            showCreateDedicatedNuban: false,
            showError: false
        })
    }

    getCartTotal = () => {
        let total = 0
        for (let i = 0; i < this.state.items.length; i++) {
            total += (this.state.items[i].amount * this.state.items[i].quantity)
        }
        return total
    }

    onDeliveryOptionChange = deliveryOption => {
        this.setState({
            ...this.state,
            deliveryOption: deliveryOption,
            deliveryFee: deliveryOption.amount
        })
    }

    render() {
        if (this.state.deliveryAddress === null) {
            return <></>
        }
        return (
            <>
                <Header title="Order Summary" backUrl="/marketplace/cart" />
                <div className="checkout">
                    {(this.state.loadingWallet || this.state.placingOrder) && <Dialog><Loading /></Dialog>}
                    {this.state.showError && <AlertDialog {...this.state.alertError} />}
                    {this.state.showPaymentSuccess && <PaymentSuccess redirectUrl="/order/all" />}
                    <div className="wallet-info">
                        <p>
                            Wallet Balance:
                        </p>
                        <div>
                            <img src={iconNaira} alt="" />
                            <NumberFormat value={this.state.wallet.balance / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                    </div>
                    <div className="summary">
                        <div className="delivery-info">
                            <p>Deliver to</p>
                            <div>
                                <p>
                                    {this.state.deliveryAddress.contactName},
                                    {this.state.deliveryAddress.area.route.name}
                                </p>
                                <button onClick={() => this.props.history.push('/marketplace/addresses')}>Change Address</button>
                            </div>
                        </div>
                        <div className="info">
                            <span>{this.state.items.length} {this.state.items.length > 1 ? 'Items' : 'Item'}</span>
                            <NumberFormat value={this.getCartTotal() / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                        <div className="info">
                            <span>Delivery Fee</span>
                            <NumberFormat value={this.state.deliveryFee / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                        <div className="info">
                            <span>Total (N)</span>
                            <NumberFormat value={this.service.getTotal() / 100} displayType={'text'} thousandSeparator={true} renderText={value => <span>{value}</span>} />
                        </div>
                    </div>

                    <br />
                    <div className="row" id="deliveryOption">
                        <label>Delivery Option</label>
                        <DeliveryOption deliveryOption={this.state.deliveryOption} areaId={this.state.deliveryAddress.area.id} itemsWeight={this.state.itemsWeight} onChange={this.onDeliveryOptionChange} />
                    </div>

                    <br /><br />

                    {this.state.showDedicatedNuban && <>
                        <h5 className="low-fund">Insufficient Fund</h5>
                        <DedicatedNuban {...this.state.dedicatedNuban} />
                        <button className="btn action" onClick={this.onReloadClick}>I have funded my Wallet</button>
                    </>}
                    {this.state.showCreateDedicatedNuban && <CreateDedicatedNuban onCreated={this.onDedicatedNubanCreated} />}
                    {this.state.wallet.balance >= this.getCartTotal() && <NumberFormat value={this.service.getTotal() / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <button className={"btn action" + (this.state.deliveryOption === null ? ' disabled' : '')} onClick={this.onPlaceOrderClick}>Place Order {value}</button>} />}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Checkout))
import React from 'react'
import './account.scss'
import iconArrowForward from '../../../../../assets/icons/arrow-forward.svg'
import { withRouter } from 'react-router-dom'
import iconInternetSkysenx from '../../../../../assets/icons/internet-skysenx.svg'
import iconDelete from '../../../../../assets/icons/delete.svg'
import NumberFormat from 'react-number-format'

const Account = props => {
    return (
        <div className="account">
            <div className="content" onClick={props.onClick}>
                <div className="details">
                    <div>
                        <h4>{props.userId}</h4>
                        <div className="logo-cont">
                            <img alt="skysenx" src={iconInternetSkysenx} />
                        </div>
                    </div>
                    <div className="info">
                        <strong>{props.name}</strong>
                        <h5>{props.plan !== null && props.plan.name}</h5>
                        {props.plan !== null && <NumberFormat value={props.plan.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />}
                        {
                            !props.expired ?
                                <span className="status">{new Date(props.expiration * 1000).toString("dd MMM yyyy HH:mm")} {Number(new Date(props.expiration * 1000).toString("H")) < 12 ? "am" : "pm"}</span> :
                                <span className="status expired">Expired</span>
                        }
                    </div>
                </div>
                <img src={iconArrowForward} alt="icon" />
            </div>
            <img src={iconDelete} alt="delete" onClick={props.onDeleteClick} />
        </div>
    )
}

export default withRouter(Account)
import React from 'react'
import Header from '../../../components/header/header.component'
import './dashboard.styles.scss'
import Service from './dashboard.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import imageNaira from '../../../assets/images/naira.png'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DedicatedNuban from '../../../components/wallet/dedicated-nuban/dedicated-nuban.component'
import CreateDedicatedNuban from '../../../components/wallet/create-dedicate-nuban/create-dedicate-nuban.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Wallet'
        this.service.loadWallet()
        this.service.loadDedicatedNuban()
    }

    onDedicatedNubanCreated = dedicatedNuban => {
        this.setState({
            ...this.state,
            dedicatedNuban: dedicatedNuban,
            showCreateDedicatedNuban: false,
            showError: false
        })
    }

    onReloadClick = () => {
        this.setState({
            ...this.state,
            loadingWallet: true,
            loadingDedicatedNuban: false,
            showError: false
        }, () => this.service.loadWallet())
    }

    render() {
        return (
            <>
                {(this.state.loadingWallet || this.state.loadingDedicatedNuban) && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                <Header title="Wallet" backUrl="/dashboard"/>
                <div className="dashboard">
                    <div className="wallet-info">
                        <p>
                           Balance:
                        </p>
                        <img src={imageNaira} alt="" />
                        <NumberFormat value={this.state.wallet.balance / 100} displayType={'text'} thousandSeparator={true} renderText={value => <strong>{value}</strong>} />
                    </div>
                    {this.state.dedicatedNuban !== null && <>
                        <DedicatedNuban {...this.state.dedicatedNuban} />
                        <button className="active" onClick={this.onReloadClick}>I have funded my Wallet</button>
                    </>}
                    {this.state.showCreateDedicatedNuban && <CreateDedicatedNuban onCreated={this.onDedicatedNubanCreated} />}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard))
import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        loading: true,
        decoder: null,
        showError: false,
        bouquet: {
            name: "Choose Bouquet",
            amount: 0,
            code: 0,
        },
        alertError: {}
    }
}

const loadDecoder = instance => {
    let decoder = getDecoderFromSession()
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/cabletv/decoder")
        .withAuthorization(instance.props.auth.authorization)
        .withData({id: decoder.id})
        .then(response => {
            handleLoadDecoderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const getDecoderFromSession = () => {
    let decoder = sessionStorage.getItem("currentDecoder")
    if(!decoder){
        window.location = "/cabletv";
        return null
    }
    return JSON.parse(decoder);
}

const handleLoadDecoderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                decoder: response.data.decoder,
                bouquet: {
                    name: response.data.decoder.bouquetInfo.Current_Bouquet,
                    amount: response.data.decoder.bouquetInfo.Renewal_Amount,
                    code: response.data.decoder.bouquetInfo.Current_Bouquet_Code
                },
                decoderBouquet: {
                    name: response.data.decoder.bouquetInfo.Current_Bouquet,
                    amount: response.data.decoder.bouquetInfo.Renewal_Amount,
                    code: response.data.decoder.bouquetInfo.Current_Bouquet_Code
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadDecoder,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadDecoder: () => loadDecoder(instance)
    }
}

export default Service
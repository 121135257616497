import React from 'react'
import { Link } from 'react-router-dom'
import iconBack from '../../../assets/icons/arrow-back.svg'
import './privacy.styles.scss'

const Privacy = () => {
    document.title = 'Privacy Policy'
    return (
        <>
            <header className="app">
                <Link className="back" onClick={() => window.location = "/dashboard"}><img alt="back" src={iconBack} /></Link>
                <p>Privacy Policy</p>
            </header>
            <div className="privacy">
                <div className="wrapper">
                    <iframe title="Privacy Policy" src="https://app.dailyruns.ng/pages/privacy.html" />
                </div>
            </div>
        </>
    )
}

export default Privacy
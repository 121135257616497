import React from 'react'
import { withRouter } from 'react-router-dom'
import './decoders.styles.scss'
import Decoder from './decoder/decoder'
import Service from './decoders.service'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import '../common.styles.scss'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Header from '../../../components/header/header.component'
import SubHeader from '../../../components/sub-header/sub-header.component'
import EmptyList from '../../../components/empty-list/empty-list.component'
import DeleteDecoder from './components/delete/delete.component'

class Decoders extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Cabletv | Saved Decoders'
        this.onLoadDecoders()
    }

    onLoadDecoders = () => {
        this.setState({
            ...this.state,
            loadingDecoders: true,
            showError: false
        }, () => this.service.loadDecoders())
    }

    onDeleteDecoder = decoder => {
        this.setState({
            ...this.state,
            showDeleteDecoder: true,
            decoderToDelete: decoder
        })
    }

    onDecoderDeleted = () => {
        let decoders = this.state.decoders.filter(decoder => decoder.id !== this.state.decoderToDelete.id)
        this.setState({
            ...this.state,
            decoders: decoders,
            showDeleteDecoder: false,
            decoderToDelete: null
        })
    }

    onCancelDeleteDecoder = () => {
        this.setState({
            ...this.state,
            showDeleteDecoder: false,
            decoderToDelete: null
        })
    }

    onDecoderSelected = decoder => {
        sessionStorage.setItem("currentDecoder", JSON.stringify(decoder))
        this.props.history.push('/cabletv/order')
    }

    render() {
        return (
            <div className="decoders">
                {this.state.loadingDecoders && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.error} />}
                {this.state.showDeleteDecoder && <Dialog><DeleteDecoder decoder={this.state.decoderToDelete} onDeleted={this.onDecoderDeleted} onCancel={this.onCancelDeleteDecoder}/></Dialog>}
                <Header title="Cable TV Subscription" backUrl="/dashboard" />
                <SubHeader title="Decoders" url="/cabletv/decoder/create" />
                <div className="records">
                    {
                        this.state.decoders.map((decoder, key) => <Decoder onClick={(index) => this.onDecoderSelected(decoder)} onDeleteClick={() => this.onDeleteDecoder(decoder)} index={key} key={key} {...decoder} />)
                    }
                </div>
                {
                    !this.state.loadingDecoders && this.state.decoders.length === 0 && <EmptyList title="oops! no saved decoder"/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cabletv: state.cabletv,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Decoders))
import Requests from "../../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        deleting: false,
        showError: false,
        alertError: {}
    }
}

const deleteBeneficiary = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/customer/services/utility/airtime/beneficiary/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData({ beneficiaryId: instance.props.beneficiary.id })
        .then(response => {
            handleDeleteBeneficiaryResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeleteBeneficiaryResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.onDeleted()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        showError: true,
        error: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDeleteBeneficiary,
            onClose: () => instance.props.history.push('/utility/airtime')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        deleteBeneficiary: () => deleteBeneficiary(instance)
    }
}

export default Service
import React from 'react'
import './network-provider.styles.scss'
import { withRouter } from 'react-router-dom'
import iconMtn from '../../../../../../assets/icons/mtn-white.svg'
import iconAirtel from '../../../../../../assets/icons/airtel-white.svg'
import iconGlo from '../../../../../../assets/icons/glo-white.svg'
import icon9Mobile from '../../../../../../assets/icons/9mobile-white.svg'
import iconArrowDown from '../../../../../../assets/icons/arrow-bottom.svg'
import ProviderChooser from './provider-chooser/provider-chooser.component'

class NetworkProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showProviderChooser: false
        }
    }

    toggleShowProviderChooser = () => {
        this.setState({
            ...this.state,
            showProviderChooser: !this.state.showProviderChooser
        })
    }

    getIconInfo = () => {
        switch (this.props.network) {
            case 0:
                return {
                    name: "MTN",
                    class: "mtn",
                    src: iconMtn
                }
            case 1:
                return {
                    name: "AIRTEL",
                    class: "airtel",
                    src: iconAirtel
                }
            case 2:
                return {
                    name: "GLO",
                    class: "glo",
                    src: iconGlo
                }
            default:
                return {
                    name: "9MOBILE",
                    class: "_9mobile",
                    src: icon9Mobile
                }
        }
    }

    onSelect = network => {
        this.props.onChange({
            target: {
                name: "network",
                value: network
            }
        })
        this.toggleShowProviderChooser()
    }

    render() {
        let iconInfo = this.getIconInfo()
        return (
            <>
                {this.state.showProviderChooser && <ProviderChooser onSelect={this.onSelect} onClose={this.toggleShowProviderChooser} />}
                <div className="network-provider" onClick={this.toggleShowProviderChooser}>
                    {
                        this.props.network !== "" && <>
                            <div className={iconInfo.class}>
                                <img alt="icon" src={iconInfo.src} />
                            </div>
                        </>
                    }
                    <h4>{this.props.network === "" ? "Select Network Provider" : iconInfo.name}</h4>
                    <img alt="icon" src={iconArrowDown} />
                </div>
            </>
        )
    }
}

export default withRouter(NetworkProvider)
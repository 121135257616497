import Requests from "../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingWallet: true,
        wallet: {
            balance: 0
        },
        showError: false,
        alertError: {}
    }
}

const loadWallet = instance => {
    Requests(instance.props)
        .get()
        .withUrl("/customer/billing/wallet")
        .withAuthorization(instance.props.auth.authorization)
        .then(response => {
            handleLoadWalletResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadWalletResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallet: false,
                wallet: response.data.wallet
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingWallet: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadWallet: () => loadWallet(instance)
    }
}

export default Service
import React from 'react'
import { Switch, Route } from "react-router-dom"
import AuthLogin from './login/login.page'
import Register from './register/register.page'
import Forgot from './forgot/forgot.page'
import './common.styles.scss'
import Verify from './verify/verify.page'
import Reset from './reset/reset.page'

const AuthController = () => {
    return (
        <Switch>
            <Route path="/auth/login" component={AuthLogin} />
            <Route path="/auth/register" component={Register} />
            <Route path="/auth/forgot" component={Forgot} />
            <Route path="/auth/verify/:token" component={Verify} />
            <Route path="/auth/reset/:token" component={Reset} />
        </Switch>
    )
}

export default AuthController
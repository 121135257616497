import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule } from '../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../utils/UIHelper'
import Device from '../../../utils/Device'
import Requests from '../../../utils/Requests'
import queryString from 'query-string'

const getDefaultState = () => {
    return {
        processing: false,
        username: "",
        password: "",
        rememberMe: false,
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(EmailValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { allowNull: false, error: "Invalid" })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })

    instance.setState({
        ...instance.state,
        errors: errors
    }, () => {
        for (let error in instance.state.errors) {
            if (instance.state.errors[error].length > 0) {
                ScrollIntoViewById(error)
                return
            }
        }
    })
    return validation.validate()
}

const login = (instance) => {
    let data = {
        email: instance.state.username.trim().toLowerCase(),
        password: instance.state.password,
        device: Device.getInfo()
    }

    Requests(instance.props)
        .post()
        .withUrl("/auth/customer/login")
        .withData(data)
        .then(response => {
            handleLoginResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const processing = (instance, isProcessing) => {
    instance.setState({
        ...instance.state,
        processing: isProcessing
    })
}

const handleLoginResponse = (instance, response) => {
    console.log(response)
    switch (response.status.code) {
        case 200:
            Device.setAuth(JSON.stringify(response.data))
            const value = queryString.parse(instance.props.location.search);
            if(typeof value.return !== 'undefined'){
                instance.props.history.push(value.return)
                return
            }
            instance.props.history.push('/dashboard')
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.status.message
                }
            })
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        processing: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLogin,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => IsValid(instance),
        processing: isProcessing => processing(instance, isProcessing),
        login: () => login(instance)
    }
}

export default Worker
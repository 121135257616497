import React from 'react'
import './create.styles.scss'
import Header from '../../../../components/header/header.component'
import { withRouter } from 'react-router-dom'
import Form from './form/form'
import Service from './create.service'
import FormHelper from '../../../../utils/FormHelper'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import '../../common.styles.scss'
import AlertDialog from '../../../../components/dialog/alert/alert.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        document.title = 'Cabletv | New Decoder'
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onAddDecoder()
    }

    onAddDecoder = () => this.setState({
        ...this.state,
        creating: true,
        showError: false,
        flag: {
            type: "",
            text: ""
        }
    }, () => this.service.addDecoder())

    onCancel = () => {
        this.props.history.push('/cabletv')
    }

    render() {
        return (
            <>
                {this.state.creating &&  <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                <Header title="CableTV Subscription" backUrl="/cabletv/decoder"/>
                <div className="create">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))

const getState = props => {
    let state = typeof props === 'undefined'? {}: props
    state.timeout = typeof state.timeout === 'undefined'? 2500: state.timeout
    return state
}

const show = (props, text) => {
    let div = document.createElement('div')
    div.className = "toast"
    div.innerHTML = text
    document.body.appendChild(div)

    let interval = setInterval(() => {
        clearInterval(interval)
        document.body.removeChild(div)
    }, props.timeout);
}

const Toast = props => {
    return {
        show: text => show(getState(props), text)
    }
}

export default Toast
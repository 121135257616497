import React from "react"
import iconClose from '../../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../../components/dialog/dialog.component"
import './provider-chooser.styles.scss'
import iconDstv from '../../../../../../../assets/icons/dstv-white.svg'
import iconGotv from '../../../../../../../assets/icons/gotv.svg'
import iconStartimes from '../../../../../../../assets/icons/startimes.svg'

const ProviderChooser = props => {
    return (
        <Dialog>
            <div className="provider-chooser">
                <h3>
                    <span>Select Cable TV Provider</span>
                    <img alt="close" src={iconClose} onClick={props.onClose} />
                </h3>
                <div className="providers">
                    <div className="provider" onClick={() => props.onSelect(0)}>
                        <div className="dstv">
                            <img alt="icon" src={iconDstv} />
                        </div>
                        <h4>Dstv</h4>
                    </div>
                    <div className="provider" onClick={() => props.onSelect(1)}>
                        <div className="gotv">
                            <img alt="icon" src={iconGotv} />
                        </div>
                        <h4>Gotv</h4>
                    </div>
                    <div className="provider" onClick={() => props.onSelect(2)}>
                        <div className="startimes">
                            <img alt="icon" src={iconStartimes} />
                        </div>
                        <h4>StarTimes</h4>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ProviderChooser
import React from 'react'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Current Password</label>
                <input type="password" name="currentPassword" value={props.currentPassword} placeholder="Enter Current Password" onChange={props.onChange} />
                <span className="error">{props.errors.currentPassword}</span>
            </div>
            <div className="form-input">
                <label>New Password</label>
                <input type="password" name="newPassword" value={props.newPassword} placeholder="Enter New Password" onChange={props.onChange} />
                <span className="error">{props.errors.newPassword}</span>
            </div>
            <div className="form-input">
                <label>Confirm New Password</label>
                <input type="password" name="newPassword2" value={props.newPassword2} placeholder="Enter New Password" onChange={props.onChange} />
                <span className="error">{props.errors.newPassword2}</span>
            </div>
            <div className="actions">
                <button className="btn action">Change Password</button>
            </div>
        </form>
    )
}

export default Form
import React from 'react'

const Form = (props) => {
    return (
        <form method="post">
            <div className="inputs">
                <div className="row" id="amount">
                    <label>Amount</label>
                    <div className="form-input">
                        <input type="number" name="amount" placeholder="Enter Amount" value={props.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Form
import React from "react"
import './fixed-amounts.styles.scss'

const FixedAmounts = props => {
    return (
        <div className="fixed-amounts">
            <span className={Number(props.amount) === 200 ? "fixed-amount active" : "fixed-amount"} onClick={() => props.onChange(200)}>N200</span>
            <span className={Number(props.amount) === 500 ? "fixed-amount active" : "fixed-amount"} onClick={() => props.onChange(500)}>N500</span>
            <span className={Number(props.amount) === 1000 ? "fixed-amount active" : "fixed-amount"} onClick={() => props.onChange(1000)}>N1000</span>
            <span className={Number(props.amount) === 2000 ? "fixed-amount active" : "fixed-amount"} onClick={() => props.onChange(2000)}>N2000</span>
        </div>
    )
}

export default FixedAmounts
import { SET_ACCOUNTS, SET_PLANS, ADD_ACCOUNT } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case ADD_ACCOUNT:
            return addAccount(state, action.payload)
        case SET_ACCOUNTS:
            return setAccounts(state, action.payload)
        case SET_PLANS:
            return setPlans(state, action.payload)
        default:
            return state
    }
}

const addAccount = (state, payload) => {
    for(let i = 0; i < state.accounts.length; i++){
        if(state.accounts[i].username === payload.username){
            return state
        }
    }
    state.accounts.push(payload)
    state.cache.accounts = new Date().getTime()
    return state
}

const setAccounts = (state, payload) => {
    state.accounts = payload
    state.cache.accounts = new Date().getTime()
    return state
}

const setPlans = (state, payload) => {
    state.plans = payload
    state.cache.plans = new Date().getTime()
    return state
}

export default Reducer
import React from 'react'
import Header from '../../../components/header/header.component'
import Order from './order/order.component'
import './orders.styles.scss'
import Service from './orders.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import AlertDialog from '../../../components/dialog/alert/alert.component'

class Orders extends React.Component {

    constructor() {
        super()
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Orders'
        this.onLoadOrders()
    }

    onLoadOrders = () => {
        this.setState({
            ...this.state,
            loadingOrders: true,
            showError: false
        }, () => this.service.loadOrders())
    }

    render() {
        return (
            <>
                <Header title="Orders" backUrl="/dashboard" />
                {this.state.loadingOrders && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="orders">
                    {!this.state.loadingOrders && this.state.orders.length === 0 && <p className="no-orders">No Orders found</p>}
                    {this.state.orders.map((order, key) => <Order key={key} {...order} />)}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Orders)
import React from 'react'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './slides.styles.scss'
import Service from './slides.service'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class Slides extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadSlides()
    }

    render() {
        return (
            <>
                {this.state.loadingSlides && <Dialog><Loading></Loading></Dialog>}
                <div className="slides">
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} transitionDuration={500} autoPlaySpeed={2500} arrows={false}>
                        {
                            this.state.slides.map((item, key) =>
                                <div key={key} className="slide" onClick={() => this.props.history.push(item.url)}>
                                    <img alt="" src={item.imageUrl} />
                                </div>
                            )
                        }
                    </Carousel>
                </div>
            </>
        )
    }
}

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1.5,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1.5,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Slides))
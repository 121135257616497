import React from "react"
import iconClose from '../../../../../assets/icons/close.svg'
import Dialog from "../../../../../components/dialog/dialog.component"
import './delivery-option-chooser.styles.scss'
import Service from "./delivery-option-chooser.service"
import DeliveryOption from "./delivery-option/delivery-option.component"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Loading from '../../../../../components/loading/loading.component'

class DeliveryOptionChooser extends React.Component {

    constructor(props){
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        this.service.loadDeliveryOptions()
    }

    render(){
        return (
            <Dialog>
                <div className="delivery-option-chooser">
                {this.state.loadingDeliveryOptions && <Dialog><Loading /></Dialog>}
                    <h3>
                        <span>Select Delivery Option</span>
                        <img alt="close" src={iconClose} onClick={this.props.onClose} />
                    </h3>
                    <div className="delivery-options">
                        {
                            this.state.deliveryOptions.map((deliveryOption, key) => <DeliveryOption key={key} {...deliveryOption} itemsWeight={this.state.itemsWeight} onClick={() => this.props.onSelect(deliveryOption)}/>)
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DeliveryOptionChooser))
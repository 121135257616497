import React from 'react'
import NumberFormat from 'react-number-format'
import StatusLabels from '../../data/status-label'
import Logo from '../../components/brands/logo/logo.component'

const Internet = props => {
    return (
        <>
            <div className="top">
                <Logo name="skysenx-internet" />
                <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Description</p>
                    <p>{props.skysenxWireless.description}</p>
                </div>
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
            </div>
        </>
    )
}

export default Internet
import React from 'react'
import './create.styles.scss'
import Header from '../../../../../components/header/header.component'
import { withRouter } from 'react-router-dom'
import LoginForm from './form/login-form'
import Service from './create.service'
import FormHelper from '../../../../../utils/FormHelper'
import { connect } from 'react-redux'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import '../../common.styles.scss'
import AlertDialog from '../../../../../components/dialog/alert/alert.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        document.title = 'Internet | New Account';
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onAddAccount()
    }

    onAddAccount = () => this.setState({
        ...this.state,
        creating: true,
        showError: false,
        flag: {
            type: "",
            text: ""
        }
    }, () => this.service.addAccount())

    onCancel = () => {
        this.props.history.push('/internet/skysenx')
    }

    render() {
        return (
            <>
                {this.state.creating &&  <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                <Header title="Skysenx Internet" backUrl="/internet/skysenx"/>
                <div className="create">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <LoginForm {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))
import React from "react"
import iconClose from '../../../../../../../assets/icons/close.svg'
import Dialog from "../../../../../../../components/dialog/dialog.component"
import './data-bundle-chooser.styles.scss'
import Service from "./data-bundle-chooser.service"
import DataBundle from "./data-bundle/data-bundle.component"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Loading from '../../../../../../../components/loading/loading.component'

class DataBundleChooser extends React.Component {

    constructor(props){
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        this.service.loadDataBundles()
    }

    render(){
        return (
            <Dialog>
                <div className="data-bundle-chooser">
                {this.state.loadingDataBundles && <Dialog><Loading /></Dialog>}
                    <h3>
                        <span>Select Data Bundle</span>
                        <img alt="close" src={iconClose} onClick={this.props.onClose} />
                    </h3>
                    <div className="data-bundles">
                        {
                            this.state.dataBundles.map((dataBundle, key) => <DataBundle key={key} {...dataBundle} onClick={() => this.props.onSelect(dataBundle)}/>)
                        }
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DataBundleChooser))